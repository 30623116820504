import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import serviceImg from '../../../assets/images/biometric.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './ServiceArea.css';

const BiometricArea = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration in milliseconds
          offset: 100, // Offset (in pixels) from the top of the element when the animation starts
        });
      }, []);


  return (
    <div className='services-section biometric'>
        <Container>
            <Row className='align-items-end'>
                <Col md={5} className='order-md-2'>
                    <div className='services-image' data-aos="zoom-in">
                        <img className='img-fluid' src={serviceImg} alt="" /> 
                    </div>
                </Col>
                <Col md={7} className="order-md-1">
                    <div className='services-data'>
                        <h3>Fingerprinting Background Check</h3>
                        <h4>Biometric Authentication</h4>
                        <p>Build a layer of trust for your organization with KYC digital protection. We offer a detailed verification of the biological traits of a person, such as fingerprints, that are difficult to forge or replicate. Fingerprint recognition, along with traditional screening methods like drug or health testing, provides additional identity verification.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default BiometricArea