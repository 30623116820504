import React, { useEffect, useState } from 'react';
// import MemberSideBar from '../Dashboard/MemberSidebar/MemberDashboardSidebar'
// import Dashboard from './Dashboard';
import { ENV } from '../../../config/config';

const MemberDashboard = ({ title, children }) => {

  const [dashboardBar, setDashboardBar] = useState(true)
  const userType = ENV.getUserKeys('type')?.type
  
  useEffect(() => {
    if (title)
      document.title = title;
    else
      document.title = "BIIVIEW";
  }, [title]);

  return (
    <>
      {
        userType && userType === 2 ?
          <div className='dashboardBody'>
            {/* <MemberSideBar dashboardBar={dashboardBar} setDashboardBar={setDashboardBar} /> */}
            <div className={dashboardBar ? 'dashboard-sitePages ddfdfd dashboard-main-width' : ' right dashboard-sitePages sitePages-width'}>
              {children}
            </div>
          </div> :
          {/* < Dashboard/> */}
    }
    </>
  )
}

export default MemberDashboard