import React from "react";
import "./FullPageLoader.css";

function FullPageLoader() {
  return (
    <React.Fragment>
      <div className="fullpage-loader-holder">
        <p className="text">
          KYC
          <span className="preloader"></span>
        </p>
      </div>
    </React.Fragment>
  );
}

export default FullPageLoader;
