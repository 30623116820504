import { useEffect, useState } from "react";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import { FaUserAlt, FaSearch, FaSignOutAlt } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import { ENV } from "../../../config/config";
import kycLogo from "../../../assets/images/logo/kyc2.png";

const Header = () => {
  // SearchBar
  const navigate = useNavigate();
  const [search, setSerach] = useState(true);
  const [bar, setBar] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    ENV.getUserKeys("accessToken") ? true : false
  );

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const logoutHandler = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  };

  return (
    <>
      <div className="header">
        <Navbar expand="lg">
          <Container fluid className="customBox-header">
            <div className="wrapHeaderBar">
              <div className="nav-flex">
                <Navbar.Brand href="/" className="text-white">
                  <img className="img-fluid" src={kycLogo} alt="BiiView"></img>
                </Navbar.Brand>
                <div className="nav-right-bar">
                  {/* <div className="wrapSearch">
                    <span
                      className="navBtns"
                      onClick={() => setSerach(!search)}
                    >
                      <FaSearch />{" "}
                    </span>
                    {search ? (
                      <span></span>
                    ) : (
                      <span className="openSearch">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="search"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        <span
                          className="searchIcon"
                          onClick={() => setSerach(!search)}
                        >
                          <GrFormClose />
                        </span>
                      </span>
                    )}
                  </div> */}
                  <div>
                    {isAuthenticated ? (
                      <Link onClick={logoutHandler} className="navBtns">
                        <FaSignOutAlt />
                      </Link>
                    ) : (
                      <Link to="/login" className="navBtns">
                        <FaUserAlt />
                      </Link>
                    )}
                  </div>
                  <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navBtns" onClick={() => setBar(!bar)}>
                      {/* <FaBars /> */}
                      <div className={bar ? "faBar faCross" : "faBar"}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </span>
                  </Navbar.Toggle>
                </div>
              </div>
              <Navbar.Collapse>
                <Nav className="ms-auto">
                  {/* <Link className="nav-link" to="/">Home</Link>
                                    <Link className="nav-link" to="/about">about</Link>
                                    <Link className="nav-link" to="/blogs">Blog</Link> */}
                  <Link className="nav-link" to="/requests">
                    My Requests
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
};
export default Header;
