import React, { useState, useEffect } from "react";
import "../Forms.css";
import { Container, Form } from "react-bootstrap";
import formLogo from "../../../../assets/images/formLogo.png";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ENV } from "../../../../config/config";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
import { forgotPassword, beforeUser } from "../../Users/users.actions";
import kycLogo from "../../../../assets/images/logo/kyc2.png";

const ForgotPass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigateToLogin = () => {
    // 👇️ navigate to /Login
    navigate("/login");
  };

  const userSelector = useSelector((state) => state.user);
  const errorSelector = useSelector((state) => state.error);

  useEffect(() => {
    if (userSelector.forgotPasswordAuth) {
      if (userSelector.forgotPasswordData.success) {
        setLoader(false);
        dispatch(beforeUser());
        setData("");
      }
    }
  }, [userSelector.forgotPasswordAuth]);

  useEffect(() => {
    if (errorSelector) {
      setLoader(false);
    }
  }, [errorSelector]);

  const submit = () => {
    let err = "";
    let payload = {};
    let input = data.trim();

    if (!input) {
      err = "Please enter email or phone number";
      return;
    } else {
      let emailRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let phoneRegex = /^[0-9]{10,12}$/;

      if (input.match(emailRegex)) {
        payload.email = input;
      } else if (input.match(phoneRegex)) {
        payload.phone = input;
      } else {
        err = "Please enter valid email or phone number";
      }
    }

    setError(err);

    if (err) return;

    setLoader(true);
    dispatch(forgotPassword(payload));
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <section className="theme-form Login">
        <div className="form-overlay"></div>
        <div className="biiview-form">
          <Container fluid className="formContainer">
            <div className="formLogo">
              <Link to="/">
                {" "}
                <img className="img-fluid" src={kycLogo} alt="KYC"></img>{" "}
              </Link>
            </div>
            <div className="wrapForm">
              <Form className="formStyle">
                <div className="formHeader border-0 mb-0">
                  <h2>Trouble logging in?</h2>
                  <div className="troubbleText">
                    <p className="mb-0 outer-p text-center forgot">
                      Enter your Email that we’ll send you a link to get back
                      your account.
                    </p>
                  </div>
                </div>
                <Form.Group
                  className="formGroup mb-0"
                  controlId="formBasicEmail"
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    value={data}
                    onChange={(e) => setData(e.target.value)}
                  ></input>
                  {error && (
                    <span style={{ fontSize: "12px" }} className="text-danger">
                      {error}
                    </span>
                  )}
                </Form.Group>
                <div className="theme-capcha">
                  <button
                    type="button"
                    className="orange-btn text-uppercase end-mb"
                    onClick={submit}
                  >
                    SEND LOGIN LINK
                  </button>
                </div>
                <div className="page-link d-flex align-items-end justify-content-center">
                  <p className="mb-0 outer-p link" onClick={navigateToLogin}>
                    BACK TO LOGIN
                  </p>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};
export default ForgotPass;
