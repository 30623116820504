import React, { useEffect, useState } from "react";
import "./DashboardHome.css";
import CaptureImage from "../CaptureImage/CaptureImage";
import VideoRecorder from "../CaptureImage/VideoRecorder";
// import SiteCards from '../Home/Explore/SiteCards';
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import { beforeCapture } from "../CaptureImage/captureImage.actions";
import { ENV } from "../../../config/config";

const DashboardHome = () => {
  const dispatch = useDispatch();
  const [isCapture, setIsCapture] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(
    ENV.getUserKeys("verificationStatus").verificationStatus
  );
  const [isCaptureVideo, setCaptureVideo] = useState(false);
  const captureImageSelector = useSelector((state) => state.captureImage);

  // useEffect(() => {
  //     if (captureImageSelector.cardImageAuth) {
  //         dispatch(beforeCapture())
  //         setCaptureVideo(true)
  //     }

  // }, [captureImageSelector.cardImageAuth])

  // useEffect(() => {
  //     if (captureImageSelector.videoAuth) {
  //         setCaptureVideo(false)
  //         setVerificationStatus(ENV.getUserKeys('verificationStatus').verificationStatus)
  //         setIsCapture(false)
  //         dispatch(beforeCapture())
  //     }
  // }, [captureImageSelector.videoAuth])

  // useEffect(() => {
  //     console.log("verificationStatus: ", verificationStatus)
  // }, [verificationStatus])

  return (
    <>
      <div className="dashboard-box h-100 d-flex justify-content-center align-items-center">
        <div className="dashboard-block dashboard-page d-flex justify-content-center align-items-center flex-column">
          <h1 className="mt-0 mb-0">Welcome to Dashboard</h1>
        </div>
      </div>
    </>
  );
};
export default DashboardHome;
