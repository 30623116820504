import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Getter from './Getter';
import routes from './routes'
import 'bootstrap/dist/css/bootstrap.min.css';

const App = (props) => {
    return (
        <>
            <Suspense fallback={<h4>Loading..</h4>}>
                <Getter />
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            exact={route.exact}
                            path={route.path}
                            element={
                                route.layout ?
                                    <route.layout {...props} title={route.name}>
                                        <route.component {...props} />
                                    </route.layout> :
                                    <route.component {...props} />

                            }
                            key={index} />
                    ))}
                </Routes>
            </Suspense>
        </>
    )
}

export default App