import React from 'react';
import './NotFound.css'
function NotFound() {
    return (
        <div id="notfound" className='d-flex flex-column justify-content-center align-items-center my-5'>
            <div class="notfound">
                <div class="notfound-404">
                    <h3>Oops! Page not found</h3>
                    <h1><span>4</span><span>0</span><span>4</span></h1>
                </div>
                <h2>we are sorry, but the page you requested was not found</h2>
            </div>
        </div>
    );
}
export default NotFound;