import React, { useState, useEffect } from 'react'
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { getUser, updateUserProfile, beforeUser } from '../Users/users.actions';
import { useNavigate } from 'react-router-dom';
import { ENV } from '../../../config/config';
import './Profile.css';
import moment from 'moment';
import $ from 'jquery'
import FullPageLoader from '../FullPageLoader/FullPageLoader';
const { userPlaceholderImage } = ENV

const Profile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [user, setUser] = useState({})
  const [image, setImage] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [loader, setLoader] = useState(true)
  const [errors, setErrors] = useState({})

  const userSelector = useSelector((state) => state.user)
  const errorSelector = useSelector((state) => state.error)

  useEffect(() => {
    if (errorSelector) {
      setLoader(false)
    }
  }, [errorSelector])

  useEffect(() => {
    const userId = ENV.getUserKeys('_id')._id
    if (userId)
      dispatch(getUser(userId))
  }, [])

  useEffect(() => {
    if (userSelector.getUserAuth) {
      let data = userSelector.getUserData?.data?.user
      setUser(data)
      setImage(data?.profileImage)
      setLoader(false)
      dispatch(beforeUser())
    }
  }, [userSelector.getUserAuth])

  useEffect(() => {
    if (userSelector.updateProfileAuth) {
      let { user } = userSelector.updateProfileData
      setLoader(false)
      if (user.emailChanged) {
        localStorage.clear()
        navigate('/login')
      }
      else {
        ENV.encryptUserData(user);
        setUser({ ...user, currentPassword: '', password: '', verifyPassword: '' })
        navigate(user.type === 1 ? '/dashboard' : '/member-dashboard')
      }
      dispatch(beforeUser())
    }
  }, [userSelector.updateProfileAuth])

  const onChangeHandler = (e) => {
    if (submitted) {
      let err = {}
      if (e.target.value) {
        setErrors({ ...errors, [e.target.name]: '' })

        if (e.target.name === 'fullName') {
          let regex = /^[a-zA-Z ]{3,30}$/;
          if (!regex.test(e.target.value))
            err = { ...err, fullName: 'Name should be atleast 3 characters long and should only contain alphabets' }

        }

        if (e.target.name === 'phone') {
          let phoneRegex = /^[0-9]{10,12}$/;
          if (!phoneRegex.test(e.target.value)) {
            err = { ...err, phone: 'Invalid Contact Number' }
          }
        }

        if (e.target.name === 'password' && e.target.value !== user.verifyPassword)
          err = { ...err, password: 'Passwords do not match' }

        setErrors(err)
      }
      else {
        setErrors({ ...errors, [e.target.name]: 'This field is required.' })
      }

    }
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onFileChange = (e) => {
    let file = e.target.files[0]
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        let result = e.target.result
        setImage(result)
        setUser({ ...user, profileImage: file })
      }
    }
  }

  const updateProfileHandler = (updateType) => {

    setSubmitted(true)
    let err = {}
    let msg = 'This field is required.'
    let payload = { ...user }

    if (updateType === 1) {

      if (!payload.fullName)
        err = { ...err, fullName: msg }
      else {
        let regex = /^[a-zA-Z ]{3,30}$/;
        if (!payload.fullName.match(regex))
          err = { ...err, fullName: 'Name should be atleast 3 characters long and should only contain alphabets' }
      }

      if (!payload.email)
        err = { ...err, email: msg }
      else {
        let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!payload.email.match(regex))
          err = { ...err, email: 'Invalid Email' }
      }

      if (payload.phone) {
        let phoneRegex = /^[0-9]{10,12}$/;
        if (!phoneRegex.test(payload.phone)) {
          err = { ...err, phone: 'Invalid Contact Number' }
        }
      }

      if (!payload.dob && payload.type == '2')
        err = { ...err, dob: msg }

      delete payload['password']
      delete payload['currentPassword']
    }
    else {
      payload = {
        password: user.password,
        currentPassword: user.currentPassword,
        verifyPassword: user.verifyPassword
      }

      if (!payload.currentPassword)
        err = { ...err, currentPassword: msg }

      if (!payload.password)
        err = { ...err, password: msg }


      if (!payload.verifyPassword)
        err = { ...err, verifyPassword: msg }

      if ((payload.password && payload.verifyPassword) &&
        payload.password !== payload.verifyPassword)
        err = { ...err, password: 'Both passwords do not match' }

    }

    setErrors(err)
    if (err.fullName || err.email || err.password || err.currentPassword || err.verifyPassword
      || err.phone || (payload.type == '2' && err.dob))
      return

    delete payload['verifyPassword']

    payload.updateType = updateType

    const formData = new FormData()
    for (let key in payload)
      if (payload[key] !== null)
        formData.append(key, payload[key])

    setLoader(true)
    dispatch(updateUserProfile(formData))

  };

  return (
    <>
      {loader && <FullPageLoader />}
      <section className='profile-sec'>
        <Container fluid>
          <div className="section-image">
            <Container>
              <Row>
                <Col xl="8" className='mb-3'>
                  <Form action="" className="form">
                    <Card className="pb-4 table-big-boy">
                      <Card.Header>
                        <Card.Header className="pl-0 card-header-title">
                          <Card.Title className='mb-0' as="h4">Profile</Card.Title>
                        </Card.Header>
                      </Card.Header>
                      <Card.Body>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>Name<span className="text-danger"> *</span></label>
                              <Form.Control
                                name="fullName"
                                value={user.fullName}
                                placeholder="John Doe"
                                type="text"
                                onChange={onChangeHandler}
                              ></Form.Control>

                            </Form.Group>
                          </Col>
                          <Col className="pl-3" md="6">
                            <Form.Group>
                              <label>Email<span className="text-danger"> *</span></label>
                              <Form.Control
                                name="email"
                                className="input-bg"
                                value={user.email}
                                placeholder="Email"
                                type="email"
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        {
                          errors.fullName || errors.email ?
                            <Row>
                              <Col md="6">
                                <span className='text-danger'>{errors.fullName ? errors.fullName : ''}</span>
                              </Col>
                              <Col className="pl-3" md="6">
                                <span className='text-danger'>{errors.email ? errors.email : ''}</span>
                              </Col>
                            </Row> : null
                        }
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Address</label>
                              <Form.Control
                                name="address"
                                value={user.address || ''}
                                placeholder="Address"
                                type="text"
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <label>Phone</label>
                              <Form.Control
                                name="phone"
                                value={user.phone || ''}
                                type="text"
                                placeholder='1234567890'
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          {
                            user.type === 2 ?
                              <Col md="6">
                                <Form.Group>
                                  <label>Date of Birth <span className="text-danger"> *</span></label>
                                  <Form.Control
                                    name="dob"
                                    value={user.dob ? moment(user.dob).format('YYYY-MM-DD') : ''}
                                    className="input-bg"
                                    type="date"
                                    onChange={onChangeHandler}
                                  ></Form.Control>
                                </Form.Group>
                              </Col> : null
                          }
                        </Row>
                        {
                          errors.phone || errors.dob ?
                            <Row>
                              <Col md="6">
                                <span className='text-danger'>{errors.phone ? errors.phone : ''}</span>
                              </Col>
                              <Col className="pl-3" md="6">
                                <span className='text-danger'>{errors.dob ? errors.dob : ''}</span>
                              </Col>
                            </Row> : null
                        }
                        <Row>
                          <Col md={12}>
                            <div className="d-flex justify-content-end align-items-center">
                              <button type="button" class="theme-btn orange-btn text-uppercase dbLogoutBtn mt-2" onClick={() => updateProfileHandler(1)}>Update Profile</button>
                            </div>
                          </Col>
                        </Row>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
                <Col xl="4" className='mb-3'>
                  <Card className="card-user table-big-boy h-100">
                    <Card.Body className='upload-img'>
                      <div className="author text-center">
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={image ? image : userPlaceholderImage}
                        />
                        <Card.Title className='text-center' as="h5">{user?.fullName || ''}</Card.Title>
                        <p className="card-description"></p>
                      </div>


                      <Form className="profile-main-form">
                        <Form.Group className="pl-3 pr-3">
                          <div className="input-file-btn-holder ">
                            <Form.Control
                              className="text-white"
                              placeholder="Company"
                              type="file"
                              varient="info"
                              accept=".png,.jpeg,.jpg"
                              id="profileImage"
                              onChange={onFileChange}
                            ></Form.Control>
                            <span className="input-file-btn">Choose Image</span>
                          </div>

                          {/* <div className="text-center d-flex justify-content-center">
                            <button type="button" class="theme-btn orange-btn text-uppercase dbLogoutBtn mt-2">Update Pic</button>
                          </div> */}

                        </Form.Group>
                      </Form>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Form action="" className="form">
                    <Card className="table-big-boy">
                      <Card.Header>
                        <Card.Header className="pl-0 card-header-title">
                          <Card.Title className='mb-0' as="h4">Change Password</Card.Title>
                        </Card.Header>
                      </Card.Header>
                      <Card.Body>


                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <label>Current Password<span className="text-danger"> *</span></label>
                              <Form.Control
                                name="currentPassword"
                                value={user.currentPassword}
                                placeholder="Current Password"
                                type="password"
                                id="currentPassword"
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>New Password<span className="text-danger"> *</span></label>
                              <Form.Control
                                name="password"
                                value={user.password}
                                placeholder="New Password"
                                type="password"
                                id="password"
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group>
                              <label>Confirm Password<span className="text-danger"> *</span></label>
                              <Form.Control
                                name="verifyPassword"
                                value={user.verifyPassword}
                                placeholder="Confirm Password"
                                type="password"
                                id="verifyPassword"
                                onChange={onChangeHandler}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        {
                          errors.currentPassword || errors.password || errors.verifyPassword ?
                            <Row>
                              <Col md="4">
                                <span className='text-danger'>{errors.currentPassword || ''}</span>
                              </Col>
                              <Col md="4">
                                <span className='text-danger'>{errors.password || ''}</span>
                              </Col>
                              <Col md="4">
                                <span className='text-danger'>{errors.verifyPassword || ''}</span>
                              </Col>
                            </Row> : null
                        }
                        <Row>
                          <Col md={12}>
                            <div className="d-flex justify-content-end align-items-center">
                              <button type="button" class="theme-btn orange-btn text-uppercase dbLogoutBtn mt-2" onClick={() => updateProfileHandler(2)}>Update Passsword</button>
                            </div>
                          </Col>
                        </Row>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Profile