import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';
import { GET_ERRORS, BEFORE_REQUESTS, REQUESTS, REQUEST, DELETE_REQUEST } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';

export const beforeRequests = () => {
    return {
        type: BEFORE_REQUESTS
    }
}

export const getRequests = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    const url = `${ENV.url}kyc/requests`;
    fetch(url, {
        method: 'POST',
        headers: {
            "content-type": "application/json",
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'edit-pro-success' })
            dispatch({
                type: REQUESTS,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "edit-pro-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const getRequest = (id) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    const url = `${ENV.url}kyc/request/${id}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'get-request-success' })
            dispatch({
                type: REQUEST,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "get-request-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


export const delRequest = (id) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    const url = `${ENV.url}kyc/request/remove/${id}`;
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'del-request-success' })
            dispatch({
                type: DELETE_REQUEST,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "del-request-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};