import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import serviceImg from '../../../assets/images/services-images.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './ServiceArea.css';

const ServiceArea = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration in milliseconds
          offset: 100, // Offset (in pixels) from the top of the element when the animation starts
        });
      }, []);


  return (
    <div className='services-section'>
        <Container>
            <Row className='align-items-center'>
                <Col md={5}>
                    <div className='services-image' data-aos="zoom-in">
                        <img className='img-fluid' src={serviceImg} alt="" /> 
                    </div>
                </Col>
                <Col md={7}>
                    <div className='services-data'>
                        <h3>ID Background Check</h3>
                        <h4>Data Verification</h4>
                        <p>Ensure an individual’s digital identity matches his real-world identity. Verify IDs, photos, and other documents to detect false identities. Our detailed screening process involves face-based metrics to authenticate that the person holding the ID is the same as in the self-shot picture (selfie) and is physically present.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ServiceArea