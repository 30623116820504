import React from 'react'
import { Container } from "react-bootstrap";

import "./Banner.css";

const BannerNote = () => {
  return (
    <div className='banner-note'>
        <Container>
            <div className='imp-note'>
                <p>Discover KYC for identity verification and financial fraud mitigation. We link physical and digital identities while ensuring compliance with AML (anti-money laundering) regulations. We help companies prevent financial crimes by delivering the most extensive checks and federal verifications about their customers' or employees’ identities, financial transactions, and monetary risks. The primary purpose of KYC is to ensure safer, simpler, and speedier identity verification. </p>
            </div>
        </Container>
    </div>
  )
}

export default BannerNote