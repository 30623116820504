import { REQUESTS, BEFORE_REQUESTS, REQUEST, DELETE_REQUEST } from '../../../redux/types';


const initialState = {
    requestsData: null,
    requestsAuth: false,

    requestData: null,
    requestAuth: false,

    requestDeleteAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUESTS:
            return {
                ...state,
                requestsData: action.payload,
                requestsAuth: true
            }
        case REQUEST:
            return {
                ...state,
                requestData: action.payload,
                requestAuth: true
            }
        case DELETE_REQUEST:
            return {
                ...state,
                requestDeleteAuth: true
            }
        case BEFORE_REQUESTS:
            return {
                ...state,
                requestsData: null,
                requestsAuth: false,
                requestData: null,
                requestAuth: false,
                requestDeleteAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}