import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../HomePage.css";

const MainLayout = ({ title, children }) => {
  useEffect(() => {
    if (title) document.title = title;
    else document.title = "KYC";
  }, [title]);

  return (
    <div className="main-layout">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
