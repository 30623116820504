import React from "react";
import Banner from "../Banner/Banner";
import BannerNote from "../Banner/BannerNote";
import ServiceArea from "../Services/ServiceArea";
import BiometricArea from "../Services/BiometricArea";
import Results from "../Results/Results";
import Verfication from "../Verfication/Verfication";
import GetStarted from "../GetStarted/GetStarted";

const Template = () => {
  return (
    <>
      <Banner />
      <BannerNote />
      <ServiceArea />
      <BiometricArea />
      <Results />
      <Verfication />
      <GetStarted />
    </>
  );
};

export default Template;
