import React, { useEffect, useState } from "react";
import "./Requests.css";
import {
  faCheck,
  faCheckDouble,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ENV } from "../../../config/config";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { beforeRequests, delRequest, getRequests } from "./requests.actions";

const Requests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [myRequests, setMyRequests] = useState();
  //   const [pagination, setPagination] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const userId = ENV.getUserKeys('_id')?._id
  const requestSelector = useSelector((state) => state.request);

  useEffect(() => {
    if(userId)
        dispatch(getRequests());
  }, []);

  useEffect(() => {
    if (requestSelector.requestsAuth) {
      setMyRequests(requestSelector.requestsData.data.requests);
      setPage(requestSelector.requestsData.data.pagination.page);
      setPages(requestSelector.requestsData.data.pagination.pages);
      setTotal(requestSelector.requestsData.data.pagination.total);
      setLimit(requestSelector.requestsData.data.pagination.limit);
      dispatch(beforeRequests());
      setLoader(false);
    }
  }, [requestSelector.requestsAuth]);

  useEffect(() => {
    if (requestSelector.requestDeleteAuth) dispatch(getRequests());
  }, [requestSelector.requestDeleteAuth]);

  const removeRequest = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete an item, it would be permanently lost.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        dispatch(delRequest(id));
      }
    });
  };
  const onPageChange = (page) => {
    setPage(page);
    setLoader(true);
    dispatch(getRequests({ page, limit }));
  };

  return loader ? (
    <FullPageLoader />
  ) : (
    <div className="request-page">
      <div className="customBox-header container-fluid">
        <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row mb-3 mb-sm-5">
          <h1 className="mt-0 mb-3 mb-sm-0">My Requests</h1>
          <button
            className="orange-btn text-uppercase"
            onClick={() => navigate("/capture-image")}
          >
            Create Request
          </button>
        </div>
      </div>
      <div className="container">
        <div className="d-flex justify-content-end mb-2 pr-3">
          <span
            style={{ color: "white", fontWeight: "bold" }}
          >{`Total : ${total}`}</span>
        </div>
        <div className="theme-table-container mb-3">
          <div className="table-responsive">
            <table className="table table-dark theme-table requests-table mb-0">
              <thead>
                <tr>
                  <th className="text-center">Sr. #</th>
                  <th>Document Type</th>
                  <th>Region</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>CNIC No.</th>
                  <th>Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {myRequests ? (
                  <>
                    {myRequests.map((val, k) => (
                      <tr key={k}>
                        <td className="text-center">
                          {limit * page - limit + k + 1}
                        </td>
                        <td>
                          {val.documentType === "id_card" ? "CNIC" : "Passport"}
                        </td>
                        <td>{val.result?.regionName}</td>
                        <td>
                          <div className="image-holder">
                            <img
                              src={
                                val.documentType === "id_card"
                                  ? ENV.assetUrl + val.result?.cardFrontImage
                                  : ENV.assetUrl + val.result?.file
                              }
                            />
                          </div>
                        </td>
                        <td>{val.result?.name}</td>
                        <td>{val.result?.id_number}</td>
                        <td>
                          <Badge
                            bg={
                              val.status === "approved"
                                ? "success"
                                : val.status === "pending"
                                ? "warning"
                                : "danger"
                            }
                          >
                            {val.status}
                          </Badge>
                        </td>
                        <td>
                          <ul className="list-unstyled action-list d-flex justify-content-center mb-0">
                            <li className="d-flex justify-content-center align-items-center mx-1 cursor-pointer">
                              <button
                                className="eye-button"
                                onClick={() => navigate(`/request/${val._id}`)}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </button>
                            </li>
                            {val.status == "pending" ? (
                              <li className="d-flex justify-content-center align-items-center mx-1 cursor-pointer">
                                <button
                                  className="check-button"
                                  onClick={() =>
                                    navigate(`/capture-video/${val._id}`)
                                  }
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                </button>
                              </li>
                            ) : val.status == "rejected" ? (
                              <li className="d-flex justify-content-center align-items-center mx-1 cursor-pointer">
                                <button
                                  className="check-button"
                                  onClick={() =>
                                    navigate(`/capture-video/${val._id}`)
                                  }
                                >
                                  <FontAwesomeIcon icon={faCheckDouble} />
                                </button>
                              </li>
                            ) : (
                              <li className="d-flex justify-content-center align-items-center mx-1 cursor-pointer"></li>
                            )}
                            <li className="d-flex justify-content-center align-items-center mx-1 cursor-pointer">
                              <button
                                className="delete-button"
                                onClick={() => removeRequest(val._id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <span className="no-data-found d-flex justify-content-center align-items-center">
                        No Data Found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          defaultCurrent={1}
          className="d-flex list-unstyled pt-3 pb-5"
          pageSize // items per page
          current={page > pages ? pages : page} // current active page
          total={pages} // total pages
          onChange={onPageChange}
          locale={localeInfo}
          showPrevNextJumpers={true}
        />
      </div>
    </div>
  );
};
export default Requests;
