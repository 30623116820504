import React, { useState, useEffect, useRef } from "react";
import { uploadVideo, beforeCapture } from "./captureImage.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { emptyError } from "../../../redux/shared/error/error.action";
import * as faceapi from "face-api.js";
import circle from "../../../assets/images/circle-dotted.svg";
import faceImg from "../../../assets/images/face-scan-circle-svgrepo-com.svg";
import greenCircle from "../../../assets/images/green-circle.svg";
import successPic from "../../../assets/images/success-pic.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const VideoRecorder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [videoURL, setVideoURL] = useState("");
  const [loader, setLoader] = useState(true);
  const [buttons, setButtons] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const captureImageSelector = useSelector((state) => state.captureImage);
  const errorSelector = useSelector((state) => state.error);
  const [message, setMessage] = useState("");
  const [record, setRecord] = useState(false);

  const [getStarted, setGetStarted] = useState(false);
  const [recordVideo, setRecordVideo] = useState(false);

  const [stateDirections, setDirections] = useState([
    "up",
    "down",
    "left",
    "right",
  ]);
  const directions = ["up", "down", "left", "right"];
  let initialNosePosition = null;
  let threshold = 50;
  let intervalId = null;

  const numberOfRectangles = 80; // Number of rectangles in the circle
  const radius = 50; // Radius of the circle

  const rectangleElements = [];

  const recordFaceID = () => {
    setGetStarted(false);
    setRecordVideo(true);
    setIsShowVideo(true);
    setMessage("Move your face to up, down, left, and right directions.");
  };

  useEffect(() => {
    if (errorSelector != null) {
      dispatch(emptyError());
      setButtons(true);
      setLoader(false);
    }
  }, [errorSelector]);

  useEffect(() => {
    if (id) {
      setLoader(false);
      setGetStarted(true);
    }
  }, [id]);

  useEffect(() => {
    if (record && stateDirections.length == 0) {
      setRecord(false);
      handleStopRecording();
      setLoader(true);
    }
  }, [stateDirections.length]);

  useEffect(() => {
    if (captureImageSelector.videoAuth) {
      setSuccess(captureImageSelector.videoAuth);
      setRecordVideo(false);
      dispatch(beforeCapture());
      setTimeout(function () {
        navigate("/requests");
      }, 5000);
    }
  }, [captureImageSelector.videoAuth]);

  // Calculate the position of each dot and create dot elements
  for (let i = 0; i < numberOfRectangles; i++) {
    const angle = (i / numberOfRectangles) * 2 * Math.PI;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    const style = {
      left: `calc(50% + ${x}%)`, // Center horizontally with a maximum width of 100%
      top: `calc(50% + ${y}%)`,
      transform: `translate(-50%, -50%) rotate(${
        (i * 360) / numberOfRectangles
      }deg)`,
    };
    rectangleElements.push(
      <div key={i} id={i} className="rectangle" style={style}></div>
    );
  }

  function calculateAverageMovement(position1, position2, axis) {
    let sum = 0;
    for (let i = 0; i < position1.length; i++) {
      sum += position1[i][axis] - position2[i][axis];
    }
    return sum / position1.length;
  }

  const getNextMessage = () => {
    if (directions.length === 0) {
      return "";
    }

    const remainingDirectionsMessage = directions.join(", ");
    return `Move your face to ${remainingDirectionsMessage} direction${
      directions.length > 1 ? "s" : ""
    }.`;
  };

  const handleDataAvailable = async ({ data }) => {
    // console.log("Data: ", data)
    if (data.size > 0) {
      setVideoURL(URL.createObjectURL(data));
      uploadVideoToServer(data);
    }
  };

  useEffect(() => {
    if (isShowVideo && videoRef.current) {
      handleStartRecording();
    }
  }, [videoRef, isShowVideo]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "user",
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
        },
      });
      videoRef.current.srcObject = stream;
      setMediaStream(stream);

      videoRef.current.addEventListener("loadedmetadata", async () => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = handleDataAvailable;
        // console.log('Loading face detection models');
        await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
        // console.log('Loaded face detection models');

        intervalId = setInterval(async () => {
          if (videoRef.current) {
            const detections = await faceapi
              .detectAllFaces(
                videoRef.current,
                new faceapi.TinyFaceDetectorOptions()
              )
              .withFaceLandmarks();
            if (detections.length > 0) {
              const nosePosition = detections[0].landmarks.getNose();
              if (initialNosePosition === null) {
                initialNosePosition = nosePosition;
                // console.log('Got initial nose position', initialNosePosition);
              } else {
                const movementX = calculateAverageMovement(
                  nosePosition,
                  initialNosePosition,
                  "x"
                );
                const movementY = calculateAverageMovement(
                  nosePosition,
                  initialNosePosition,
                  "y"
                );

                // console.log(`Movement: ${movementX}, ${movementY}`);

                if (
                  Math.abs(movementX) > threshold ||
                  Math.abs(movementY) > threshold
                ) {
                  if (
                    !record &&
                    mediaRecorderRef.current.state !== "recording"
                  ) {
                    // console.log("Starting Recording===> movementX: ", movementX, "movementY :", movementY)
                    setRecord(true); // Set isRecording to true to indicate recording is in progress
                    mediaRecorderRef.current.start();
                  }
                  let newDirection = null;
                  if (Math.abs(movementX) > Math.abs(movementY)) {
                    newDirection = movementX > 0 ? "right" : "left";
                    const index = directions.indexOf(newDirection);
                    if (index > -1) {
                      directions.splice(index, 1);
                      if (newDirection == "right")
                        for (let i = 0; i <= 79; i++) {
                          document.getElementById(i).className =
                            "rectangle green";
                          if (i == 9) i = 69;
                        }
                      else
                        for (let i = 30; i <= 49; i++) {
                          document.getElementById(i).className =
                            "rectangle green";
                          if (i == 9) i = 69;
                        }
                    }
                  } else {
                    newDirection = movementY > 0 ? "down" : "up";
                    const index = directions.indexOf(newDirection);
                    if (index > -1) {
                      directions.splice(index, 1);
                      if (newDirection == "down")
                        for (let i = 10; i <= 29; i++) {
                          document.getElementById(i).className =
                            "rectangle green";
                          if (i == 9) i = 69;
                        }
                      else
                        for (let i = 50; i <= 69; i++) {
                          document.getElementById(i).className =
                            "rectangle green";
                          if (i == 9) i = 69;
                        }
                    }
                  }
                  setDirections(directions);
                  setMessage(getNextMessage());
                }
              }
            }
          } else {
            clearInterval(intervalId);
            if (
              mediaRecorderRef.current &&
              mediaRecorderRef.current.state !== "inactive"
            ) {
              mediaRecorderRef.current.ondataavailable = null;
              mediaRecorderRef.current.stop();
            }
          }
        }, 100);
      });
    } catch (error) {
      console.error("Error while starting recording:", error);
    }
  };

  const handleStopRecording = async () => {
    // console.log('videoRef.current:', videoRef.current);
    setIsShowVideo(false);
    if (mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.stop();
    }
    clearInterval(intervalId);
    // setRecording(false);
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    // videoRef.current.srcObject.getTracks().forEach(track => track.stop());
  };

  const uploadVideoToServer = (videoFile) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("video", videoFile);
    formData.append("verificationId", id);
    dispatch(uploadVideo(formData));
  };

  return loader ? (
    <FullPageLoader />
  ) : (
    <div className="dashboard-box video-recording h-100">
      <div className="mb-5 dashboard-block p-0 bg-none">
        <button
          className="back-button text-uppercase"
          onClick={() => navigate(`/requests`)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      </div>
      {getStarted && (
        <div className="dashboard-block d-flex justify-content-center align-items-center flex-column">
          <h1 className="mt-0 mb-4 mb-md-5">How to setup faceID</h1>
          <div className="face-area">
            <img className="img-fluid" src={circle} alt="" />
            <div className="face">
              <img className="img-fluid" alt="" src={faceImg} />
            </div>
          </div>
          <div className="button-area">
            <button
              className="orange-btn text-uppercase"
              onClick={recordFaceID}
            >
              Get Started
            </button>
          </div>
        </div>
      )}

      {recordVideo && (
        <div className="dashboard-block d-flex justify-content-center align-items-center flex-column">
          <h1 className="mt-0 mb-4 mb-md-5">Recording Video...</h1>
          {message && <p>{message}</p>}
          <div className="video-holder">
            <video ref={videoRef} autoPlay muted />
            {rectangleElements}
          </div>
          {buttons && !isShowVideo && (
            <div className="try-buttons d-flex d-wrap justify-content-center pt-2">
              <div className="mx-2 mb-3">
                <button
                  className="orange-btn text-uppercase"
                  onClick={() => navigate(`/requests`)}
                >
                  Cancel
                </button>
              </div>
              <div className="mx-2 mb-3">
                <button
                  className="orange-btn text-uppercase"
                  onClick={() => recordFaceID()}
                >
                  Try Again
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {isSuccess && (
        <div className="dashboard-block d-flex justify-content-center align-items-center flex-column">
          <h1 className="mt-0 mb-4 mb-md-5">Face Verification</h1>
          <div className="face-area">
            <img className="img-fluid" src={greenCircle} alt="" />
            <div className="face-success">
              <img className="img-fluid" alt="" src={successPic} />
            </div>
          </div>
          <div className="text-center">
            {/* <p>Face ID is now <br />set up</p> */}
            <p>Verification Successful</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoRecorder;
