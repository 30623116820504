import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Terms = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 100, // Offset (in pixels) from the top of the element when the animation starts
    });
  }, []);


  return (
    <>
        <section className='terms-sec'>
            <Container className='customBox'>
              <div className='' data-aos="fade-right">
                <h1>Terms & <span className='theme'>Conditions</span>.</h1>
                <p className='mb-0'>These are the terms of use for KYC and its connected web pages (collectively, the Site). The site is operated by KYC, Ltd. (we, us, and our). These terms of service do not apply to any of our products or services that have separate agreements.</p>
              </div>
            </Container>
        </section>
    </>
  )
}

export default Terms