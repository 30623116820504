import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ENV } from './config/config';
import { useSelector, useDispatch } from "react-redux";

const Getter = (props) => {
    const navigate = useNavigate();
    const userId = ENV.getUserKeys('_id')?._id
    const userType = ENV.getUserKeys('type')?.type
    const errorSelector = useSelector((state) => state.error)
    const excludedRoutes = [
        'signup',
        'forgot',
        'reset-password',
        'about',
        'contact',
        'terms-conditions',
        'privacy-policy',
        'blog-details',
        'blogs',
        'invite-user'
    ]

    const userRoutes = [
        'dashboard'
    ]
    const memberRoutes = [
        'member-dashboard',
        'content'
    ]

    useEffect(() => {

        if (window.location.pathname) {

            let location = window.location.pathname.split('/')[1]

            // if user is not logged in and is visiting any protected route, redirect him to login 
            if (!userId || !localStorage.getItem('encuse')) {
                if (window.location.pathname !== '/' && excludedRoutes.indexOf(location) === -1)
                    navigate('/login')
            }

            // if user is logged in and visits login screen , redirect him to respective dashboard
            if (userId && (location.indexOf('login') > -1 || location.indexOf('signup') > -1))
                navigate(userType === 2 ? '/member-dashboard' : '/dashboard')

            // user cannot access member routes and vice versa
            if (userId && userType) {
                if (userType === 1 && memberRoutes.indexOf(location) > -1)
                    navigate('/dashboard')
                else if (userType === 2 && userRoutes.indexOf(location) > -1)
                    navigate('/member-dashboard')
            }
        }

    }, [window.location.pathname])

    useEffect(() => {
        // when an error is received
        if (errorSelector) {
            // if user is not found, clear storage and redirect to connect wallet screen
            if (errorSelector.user404) {
                localStorage.clear()
                navigate('/login')
            }

            // user disabled due to expired session or some other error
            if (errorSelector.userDisabled) {
                localStorage.clear()
                navigate('/login')
            }
        }
    }, [errorSelector])

    return (
        <>
        </>
    )
}

export default Getter
