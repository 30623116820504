import { combineReducers } from 'redux'
import userReducer from '../components/Frontend/Users/users.reducer'
import errorReducer from './shared/error/error.reducer'
import siteSettingsReducer from '../components/Frontend/SiteSettings/settings.reducer'
// import dashboardReducer from '../components/Frontend/Dashboard/DasboardPage/dashboard.reducer'
import captureImageReducer from '../components/Frontend/CaptureImage/captureImage.reducer'
import requestsReducer from '../components/Frontend/Requests/requests.reducer'


export default combineReducers({
    error: errorReducer,
    siteSettings: siteSettingsReducer,
    user: userReducer,
    // dashboard: dashboardReducer,
    captureImage: captureImageReducer,
    request: requestsReducer
})