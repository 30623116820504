import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import bannerIMg from "../../../assets/images/banner-img.png";
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/4XeA9pu5Nk.json';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import "./Banner.css";

const Banner = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 100, // Offset (in pixels) from the top of the element when the animation starts
    });
  }, []);


  return (
    <div className="banner">
      <Container>
        <Row>
          <Col md={6}>
            <div className="banner-data" data-aos="fade-right">
                <h1>Know Your <strong>CUSTOMER</strong></h1>
                <p>Preventing Frauds! KYC is a comprehensive identity verification solution for businesses and financial institutions. Use this intuitive platform to identify criminal elements and prevent illegal activities at the workplace.</p>
                <span className="banner-lottie">
                    <Lottie
                        options={{
                            animationData: animationData,
                            loop: true,
                            autoplay: true,
                        }}
                        />
                </span>  
                <Link to="/requests" className="main-button">GET STARTED</Link>
            </div>            
          </Col>
          <Col md={6}>
            <div className="banner-img" data-aos="zoom-in">
              <img className="img-fluid" src={bannerIMg} alt="Banner Img" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
