import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';
import { GET_ERRORS, CARD_IMAGE, FACE_VIDEO, BEFORE_CAPTURE_IMAGE, USER_LOGIN } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';

export const beforeCapture = () => {
    return {
        type: BEFORE_CAPTURE_IMAGE
    }
}

export const uploadVideo = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    const url = `${ENV.url}kyc/video-verify`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'edit-pro-success' })
            dispatch({
                type: FACE_VIDEO,
                payload: data
            })
        } else {
            toast.error(data.message, { toastId: "edit-pro-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const uploadCard = (body) => dispatch => {

    const { accessToken } = ENV.getUserKeys('accessToken')

    dispatch(emptyError());
    const url = `${ENV.url}kyc/card-verify`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': accessToken || ''
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.success(data.message, { toastId: 'edit-pro-success' })
            dispatch({
                type: CARD_IMAGE,
                payload: data.data
            })
        } else {
            toast.error(data.message, { toastId: "edit-pro-error" })
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};


// export const login = (payload) => dispatch => {
//     dispatch(emptyError());
//     const url = `${ENV.url}auth/login/`;
//     fetch(url, {
//         method: 'POST',
//         headers: {
//             'content-type': 'application/json',
//             'Authorization': ENV.Authorization,
//             'x-auth-token': ENV.x_auth_token
//         },
//         body: JSON.stringify(payload)

//     }).then(res => res.json()).then(data => {
//         if (data.success) {
//             ENV.encryptUserData(data.data);
//             dispatch({
//                 type: USER_LOGIN,
//                 payload: data
//             })
//         } else {
//             toast.error(data.message, { toastId: "login-error" })
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: data
//             })
//         }
//     }).catch(errors => {
//         dispatch({
//             type: GET_ERRORS,
//             payload: errors
//         })
//     })
// };

// export const signup = (payload) => dispatch => {
//     dispatch(emptyError());
//     const url = `${ENV.url}auth/register/`;
//     fetch(url, {
//         method: 'POST',
//         headers: {
//             'content-type': 'application/json',
//             'Authorization': ENV.Authorization,
//             'x-auth-token': ENV.x_auth_token
//         },
//         body: JSON.stringify(payload)

//     }).then(res => res.json()).then(data => {
//         if (data.success) {
//             ENV.encryptUserData(data.data);
//             dispatch({
//                 type: USER_LOGIN,
//                 payload: data
//             })
//             // toast.success(data.message, { toastId: "signup-success" })
//             // // ENV.encryptUserData(data.data);
//             // dispatch({
//             //     type: USER_SIGNUP,
//             //     payload: data
//             // })
//         } else {
//             toast.error(data.message, { toastId: "signup-error" })
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: data
//             })
//         }
//     }).catch(errors => {
//         dispatch({
//             type: GET_ERRORS,
//             payload: errors
//         })
//     })
// };

// export const forgotPassword = (payload) => dispatch => {
//     dispatch(emptyError());
//     const url = `${ENV.url}auth/forgot-password/`;
//     fetch(url, {
//         method: 'POST',
//         headers: {
//             'content-type': 'application/json',
//             'Authorization': ENV.Authorization,
//             'x-auth-token': ENV.x_auth_token
//         },
//         body: JSON.stringify(payload)

//     }).then(res => res.json()).then(data => {
//         if (data.success) {
//             toast.success(data.message, { toastId: 'forgot-pass-success' })
//             dispatch({
//                 type: FORGOT_PASSWORD,
//                 payload: data
//             })
//         } else {
//             toast.error(data.message, { toastId: "forgot-pass-error" })
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: data
//             })
//         }
//     }).catch(errors => {
//         dispatch({
//             type: GET_ERRORS,
//             payload: errors
//         })
//     })
// };

// export const resetPassword = (payload) => dispatch => {
//     dispatch(emptyError());
//     const url = `${ENV.url}auth/reset-password/`;
//     fetch(url, {
//         method: 'POST',
//         headers: {
//             'content-type': 'application/json',
//             'Authorization': ENV.Authorization,
//             'x-auth-token': ENV.x_auth_token
//         },
//         body: JSON.stringify(payload)

//     }).then(res => res.json()).then(data => {
//         if (data.success) {
//             toast.success(data.message, { toastId: 'reset-pass-success' })
//             dispatch({
//                 type: RESET_PASSWORD,
//                 payload: data
//             })
//         } else {
//             toast.error(data.message, { toastId: "reset-pass-error" })
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: data
//             })
//         }
//     }).catch(errors => {
//         dispatch({
//             type: GET_ERRORS,
//             payload: errors
//         })
//     })
// };

// export const getUser = (id) => dispatch => {
//     dispatch(emptyError());
//     const url = `${ENV.url}users/get/${id}`;
//     fetch(url, {
//         method: 'GET',
//         headers: {
//             'content-type': 'application/json',
//             'Authorization': ENV.Authorization,
//             'x-auth-token': ENV.x_auth_token
//         },

//     }).then(res => res.json()).then(data => {

//         if (data.success) {
//             dispatch({
//                 type: GET_USER,
//                 payload: data
//             })
//         } else {
//             toast.error(data.message, { toastId: "get-user-error" })
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: data
//             })
//         }
//     }).catch(errors => {
//         dispatch({
//             type: GET_ERRORS,
//             payload: errors
//         })
//     })
// };

// export const getUserPlanDetails = () => dispatch => {

//     const { accessToken } = ENV.getUserKeys('accessToken')

//     dispatch(emptyError());
//     const url = `${ENV.url}users/plan-details`;
//     fetch(url, {
//         method: 'GET',
//         headers: {
//             'Authorization': ENV.Authorization,
//             'x-auth-token': ENV.x_auth_token,
//             'x-access-token': accessToken || ''
//         },

//     }).then(res => res.json()).then(data => {
//         if (data.success) {
//             dispatch({
//                 type: GET_PLAN_DETAILS,
//                 payload: data
//             })
//         } else {
//             toast.error(data.message, { toastId: "active-plan-error" })
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: data
//             })
//         }
//     }).catch(errors => {
//         dispatch({
//             type: GET_ERRORS,
//             payload: errors
//         })
//     })
// };

