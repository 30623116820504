import React, { useState, useEffect } from "react";
import "../Forms.css";
import { Container, Form, Tab, Tabs } from "react-bootstrap";
import formLogo from "../../../../assets/images/formLogo.png";
import { useNavigate, Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from "react-redux";
import { signup, beforeUser } from "../../Users/users.actions";
import moment from "moment";
import { ENV } from "../../../../config/config";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
import kycLogo from "../../../../assets/images/logo/kyc2.png";
const { recaptchaSiteKey } = ENV;

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signupData, setSignupData] = useState({
    fullName: "",
    email: "",
    password: "",
    verifyPassword: "",
    dob: "",
  });
  const [captcha, setCaptcha] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [key, setKey] = useState("1");

  const userSelector = useSelector((state) => state.user);
  const errorSelector = useSelector((state) => state.error);

  useEffect(() => {
    if (userSelector.userSignupAuth) {
      if (userSelector.userSignupData.success) {
        setLoader(false);
        dispatch(beforeUser());
        navigate("/login");
      }
    }
  }, [userSelector.userSignupAuth]);

  useEffect(() => {
    if (userSelector.userAuth) {
      if (userSelector.userData.success) {
        setLoader(false);
        dispatch(beforeUser());
        // navigate(
        //   userSelector.userData.data.type === 2
        //     ? "/member-dashboard"
        //     : "/dashboard"
        // );
        navigate("/requests");
      }
    }
  }, [userSelector.userAuth]);

  useEffect(() => {
    if (errorSelector) {
      setLoader(false);
    }
  }, [errorSelector]);

  function onChange(value) {
    if (submitted && value) setErrors({ ...errors, captcha: "" });
    setCaptcha(value);
  }

  const navigateToLogin = () => {
    // 👇️ navigate to /Login
    navigate("/login");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      signupHandler();
    }
  };

  const onChangeHandler = (e) => {
    if (submitted) {
      if (e.target.value) {
        setErrors({ ...errors, [e.target.name]: "" });

        if (
          (e.target.name === "verifyPassword" &&
            e.target.value !== signupData.password) ||
          (e.target.name === "password" &&
            e.target.value !== signupData.verifyPassword)
        )
          setErrors({
            ...errors,
            [e.target.name]: "Both passwords do not match",
          });
      } else {
        setErrors({ ...errors, [e.target.name]: "This field is required." });
      }
    }
    setSignupData({ ...signupData, [e.target.name]: e.target.value });
  };

  const signupHandler = () => {
    setSubmitted(true);
    let fullNameErr = "";
    let emailErr = "";
    let passErr = "";
    let captchaErr = "";
    let verifyPassErr = "";
    let dobErr = "";
    let msg = "This field is required.";

    if (!signupData.fullName) fullNameErr = msg;
    else {
      let regex = /^[a-zA-Z ]{3,30}$/;
      if (!signupData.fullName.match(regex))
        fullNameErr =
          "Name should be atleast 3 characters long and should only contain alphabets";
    }

    if (!signupData.email) emailErr = msg;
    else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!signupData.email.match(regex)) emailErr = "Invalid Email";
    }

    if (!signupData.password) passErr = msg;

    if (!signupData.verifyPassword) verifyPassErr = msg;

    if (
      signupData.password &&
      signupData.verifyPassword &&
      signupData.password !== signupData.verifyPassword
    )
      verifyPassErr = "Passwords do not match";

    if (!signupData.dob && key == "2") dobErr = msg;
    else {
      let date = moment(signupData.dob).format("YYYY");
      let presentDate = moment(new Date()).format("YYYY");
      let dateDifference = presentDate - date;

      if (dateDifference < 8) dobErr = "Invalid Date";
    }

    if (!captcha) captchaErr = msg;

    setErrors({
      fullName: fullNameErr,
      email: emailErr,
      password: passErr,
      verifyPassword: verifyPassErr,
      captcha: captchaErr,
      dob: dobErr,
    });

    if (
      fullNameErr ||
      emailErr ||
      passErr ||
      captchaErr ||
      verifyPassErr ||
      (key == "2" && dobErr)
    )
      return;

    delete signupData["verifyPassword"];
    document.getElementById("verifyPasswordInput").value = "";
    setLoader(true);
    dispatch(signup(signupData));
  };

  const tabChangeHandler = (k) => {
    if (key !== k) {
      setKey(k);
      setErrors({});
      setSignupData({
        fullName: "",
        email: "",
        password: "",
        verifyPassword: "",
        dob: "",
      });
    }
  };

  const onDateChangeHandler = (e) => {
    if (submitted) {
      let date = moment(e.target.value).format("YYYY");
      let presentDate = moment(new Date()).format("YYYY");
      let dateDifference = presentDate - date;

      if (dateDifference < 8) setErrors({ ...errors, dob: "Invalid Date" });
    }

    setSignupData({ ...signupData, dob: e.target.value });
  };

  const tabForm = (key) => (
    <Form className="formStyle">
      <div className="formHeader">
        {key == "1" ? <h2>Create New Account</h2> : <h2>Become Member</h2>}
        <div className="d-flex align-items-end justify-content-center">
          <p className="mb-0">
            {key == "1" ? "EXISTING USER?" : "ALREADY MEMBER?"}
          </p>
          <button onClick={navigateToLogin} className="link orangeLinkBtn ms-2">
            LOGIN
          </button>
        </div>
      </div>

      <Form.Group
        className="formGroup"
        controlId="formBasicEmail"
        onKeyPress={handleKeyPress}
      >
        <Form.Label>Full Name</Form.Label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter Your Name"
          name="fullName"
          value={signupData.fullName}
          onChange={onChangeHandler}
        ></input>
        {errors && errors.fullName && (
          <span style={{ fontSize: "12px" }} className=" text-danger">
            {errors.fullName}
          </span>
        )}
      </Form.Group>

      <Form.Group
        className="formGroup"
        controlId="formBasicEmail"
        onKeyPress={handleKeyPress}
      >
        <Form.Label>Email Address</Form.Label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter Your Email Address"
          name="email"
          value={signupData.email}
          onChange={onChangeHandler}
        ></input>
        {errors && errors.email && (
          <span style={{ fontSize: "12px" }} className=" text-danger">
            {errors.email}
          </span>
        )}
      </Form.Group>

      <Form.Group
        className="formGroup"
        controlId="formBasicEmail"
        onKeyPress={handleKeyPress}
      >
        <Form.Label>Password</Form.Label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter Your Password"
          name="password"
          value={signupData.password}
          onChange={onChangeHandler}
        ></input>
        {errors && errors.password && (
          <span style={{ fontSize: "12px" }} className=" text-danger">
            {errors.password}
          </span>
        )}
      </Form.Group>

      <Form.Group
        className="formGroup"
        controlId="formBasicEmail"
        onKeyPress={handleKeyPress}
      >
        <Form.Label>Verify Password</Form.Label>
        <input
          type="password"
          className="form-control"
          placeholder="Re-Enter Password"
          id="verifyPasswordInput"
          name="verifyPassword"
          value={signupData.verifyPassword}
          onChange={onChangeHandler}
        ></input>
        {errors && errors.verifyPassword && (
          <span style={{ fontSize: "12px" }} className=" text-danger">
            {errors.verifyPassword}
          </span>
        )}
      </Form.Group>

      <div className="theme-capcha">
        <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChange} />
        {errors && errors.captcha && (
          <span style={{ fontSize: "12px" }} className=" text-danger">
            {errors.captcha}
          </span>
        )}
        <button
          type="button"
          className="orange-btn text-uppercase"
          onClick={signupHandler}
        >
          sign up
        </button>
      </div>
    </Form>
  );
  return (
    <>
      {loader && <FullPageLoader />}
      <section className="theme-form Login">
        <div className="form-overlay"></div>
        <div className="biiview-form">
          <Container fluid className="formContainer">
            <div className="formLogo">
              <Link to="/">
                {" "}
                <img className="img-fluid" src={kycLogo} alt="KYC"></img>{" "}
              </Link>
            </div>
            <div className="wrapForm">
              <Form className="formStyle">
                <div className="formHeader">
                  {key == "1" ? (
                    <h2>Create New Account</h2>
                  ) : (
                    <h2>Become Member</h2>
                  )}
                  <div className="d-flex align-items-end justify-content-center">
                    <p className="mb-0">
                      {key == "1" ? "EXISTING USER?" : "ALREADY MEMBER?"}
                    </p>
                    <button
                      onClick={navigateToLogin}
                      className="link orangeLinkBtn ms-2"
                    >
                      LOGIN
                    </button>
                  </div>
                </div>

                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Full Name</Form.Label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Name"
                    name="fullName"
                    value={signupData.fullName}
                    onChange={onChangeHandler}
                  ></input>
                  {errors && errors.fullName && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.fullName}
                    </span>
                  )}
                </Form.Group>

                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Email Address</Form.Label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email Address"
                    name="email"
                    value={signupData.email}
                    onChange={onChangeHandler}
                  ></input>
                  {errors && errors.email && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.email}
                    </span>
                  )}
                </Form.Group>

                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Password</Form.Label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter Your Password"
                    name="password"
                    value={signupData.password}
                    onChange={onChangeHandler}
                  ></input>
                  {errors && errors.password && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.password}
                    </span>
                  )}
                </Form.Group>

                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Verify Password</Form.Label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Re-Enter Password"
                    id="verifyPasswordInput"
                    name="verifyPassword"
                    value={signupData.verifyPassword}
                    onChange={onChangeHandler}
                  ></input>
                  {errors && errors.verifyPassword && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.verifyPassword}
                    </span>
                  )}
                </Form.Group>

                <div className="theme-capcha">
                  <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChange} />
                  <div className="d-flex align-items-end justify-content-center mt-lg-4 mt-2">
                    <p className="mb-0 outer-p ">I agree with the</p>
                    <Link to="/terms-conditions" className="link ms-2 themeLink">
                      {" "}
                      Terms & Conditions.
                    </Link>
                  </div>
                  {errors && errors.captcha && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.captcha}
                    </span>
                  )}
                  <button
                    type="button"
                    className="orange-btn text-uppercase"
                    onClick={signupHandler}
                  >
                    sign up
                  </button>
                </div>
              </Form>
            </div>
            {/* <div className='wrapForm'>
                            <Tabs
                                activeKey={key}
                                onSelect={tabChangeHandler}
                            >
                                <Tab eventKey="1" title="User">
                                    {tabForm('1')}
                                </Tab>
                                <Tab eventKey="2" title="Member">
                                    {tabForm('2')}
                                </Tab>

                            </Tabs>

                           
                        </div> */}
          </Container>
        </div>
      </section>
    </>
  );
};
export default Signup;
