import React from 'react'
import { Container, Accordion } from "react-bootstrap";
import Lottie from 'react-lottie';
import VerifyLottie from '../../../assets/lottie/JWvw3ZgpB5 (3).json';

import './Verfication.css';

const Verfication = () => {
  return (
    <div className='verfication'>
        <Container>
            <div className='verification-box'>
                <h4>Our Verification Services Include</h4>
                <div className='verification-split'>
                    <div className='verfication-faqs'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Identity Validation</Accordion.Header>
                                <Accordion.Body>
                                Be it your customer or an employee, a comprehensive background check is mandatory to prevent online identity theft and fraud. We run thorough checks for documentation and verify address histories against various data sources to validate one’s national identity information.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Employee Authentication</Accordion.Header>
                                <Accordion.Body>
                                Make your employment screening and onboarding process quick, easy, and secure with us. We help employers determine the legality of candidate credentials, employment, and academic history. We offer a mix-and-match of employee verification, including financial and criminal background checks.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Biometric Verification</Accordion.Header>
                                <Accordion.Body>
                                We verify identities through different biological traits, like fingerprints unique to each individual. We run fingerprint background checks to help organizations protect their assets and reputation while fostering trust among current customers and employees.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='verfication-lottie'>
                        <Lottie
                            options={{
                                animationData: VerifyLottie,
                                loop: true,
                                autoplay: true,
                            }}
                            />
                    </div>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default Verfication