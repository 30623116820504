import React from 'react'
import { Container } from 'react-bootstrap';
import ImageOne from '../../../assets/images/result-card.png';
import ImageTwo from '../../../assets/images/result-person.png';
import ImageThree from '../../../assets/images/result-vector.png';
import ImageFour from '../../../assets/images/result-hand.png';
import ImageFive from '../../../assets/images/result-program.png';

import './Results.css';

const box = [
    {id:1, title: "Know Your Customer with the highest level of security and risk mitigation.", image: ImageTwo },
    {id:2, title: "Employee Verification to prevent fraudulent activities and monetary crimes. ", image: ImageOne  },
    {id:3, title: "Ensure Vendor Compliance with the company’s legal and regulatory requirements.", image: ImageFour },
    {id:4, title: "Unified Program Management solution for different projects and fraud scenarios.", image: ImageFive },
    {id:5, title: "Uniformity throughout the screening procedure for decreased exposure to risk.", image: ImageThree },
]

const Results = () => {
  return (
    <div className='results'>
        <Container>
            <div className='result-head text-center'>
                <h4>Get efficient and reliable results quickly through our careful screening process and multi-layered approach, taking you to the next level of fraud protection.</h4>
            </div>
            <div className='result-boxs'>
                {
                    box.map((i, index) => {
                        return (
                            <div className='box text-center' key={index}>
                                <div className='box-image'>
                                    <img className='img-fluid' alt="" src={i.image} />
                                 </div>  
                                 <h5>{i.title}</h5> 
                            </div>
                        )
                    })
                }
            </div>
        </Container>
    </div>
  )
}

export default Results