import React, { useState, useEffect } from "react";
import "../Forms.css";
import { Container, Form } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ENV } from "../../../../config/config";
import { resetPassword, beforeUser } from "../../Users/users.actions";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
import kycLogo from "../../../../assets/images/logo/kyc2.png";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [errors, setErrors] = useState({});

  const userSelector = useSelector((state) => state.user);
  const errorSelector = useSelector((state) => state.error);

  useEffect(() => {
    if (userSelector.resetPasswordAuth) {
      if (userSelector.resetPasswordData.success) {
        setLoader(false);
        navigate("/login");
        dispatch(beforeUser());
      }
    }
  }, [userSelector.resetPasswordAuth]);

  useEffect(() => {
    if (errorSelector) {
      setLoader(false);
    }
  }, [errorSelector]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  };

  const submit = () => {
    let passwordErr = "";
    let verifyPassErr = "";

    if (!password) passwordErr = "This field is required";

    if (!verifyPassword) verifyPassErr = "This field is required";

    if (password !== verifyPassword) verifyPassErr = "Passwords do not match";

    setErrors({ password: passwordErr, verifyPassword: verifyPassErr });

    if (passwordErr || verifyPassErr) return;

    setLoader(true);
    dispatch(resetPassword({ password, resetPasswordToken: token }));
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <section className="theme-form Login">
        <div className="form-overlay"></div>
        <div className="biiview-form">
          <Container fluid className="formContainer">
            <div className="formLogo">
              <Link to="/">
                {" "}
                <img className="img-fluid" src={kycLogo} alt="KYC"></img>{" "}
              </Link>
            </div>
            <div className="wrapForm">
              <Form className="formStyle">
                <div className="formHeader border-0">
                  <h2>Reset Password</h2>
                  <div className="">
                    <p className="mb-0 outer-p text-center forgot">
                      Enter your new password in below fields
                    </p>
                  </div>
                </div>
                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Password</Form.Label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter Your Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>
                  {errors && errors.password && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.password}
                    </span>
                  )}
                </Form.Group>

                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Verify Password</Form.Label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Re-Enter Password"
                    name="verifyPassword"
                    value={verifyPassword}
                    onChange={(e) => setVerifyPassword(e.target.value)}
                  ></input>
                  {errors && errors.verifyPassword && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.verifyPassword}
                    </span>
                  )}
                </Form.Group>

                <div className="theme-capcha">
                  <button
                    type="button"
                    className="orange-btn text-uppercase end-mb"
                    onClick={submit}
                  >
                    Reset
                  </button>
                </div>
                <div className="page-link d-flex align-items-end justify-content-center">
                  <p
                    className="mb-0 outer-p link"
                    onClick={() => navigate("/forgot")}
                  >
                    BACK TO FORGOT PASSWORD
                  </p>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};
export default ResetPassword;
