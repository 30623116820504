import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { emptyError } from "../../../redux/shared/error/error.action";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import { beforeCapture, uploadCard } from "./captureImage.actions";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const options = [
  { value: "id_card", label: "CNIC" },
  { value: "passport", label: "Passport" },
];

const CaptureImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [frontImageData, setFrontImageData] = useState(null);
  const [backImageData, setBackImageData] = useState(null);
  const [stream, setStream] = useState(null);
  const [chooseImage, setChooseImage] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isShowButtons, setIsShowButtons] = useState(true);
  const [cardsImageAuth, setCardsImageAuth] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const captureImageSelector = useSelector((state) => state.captureImage);
  // const verificationStatus = ENV.getUserKeys('verificationStatus').verificationStatus;
  const errorSelector = useSelector((state) => state.error);
  const videoRef = useRef(null);

  // useEffect(() => {
  //     if (verificationStatus == 'submitted') {
  //         setIsShowButtons(false)
  //         setCardsImageAuth(true)
  //         setLoader(false)
  //     }
  //     else if (verificationStatus == 'approved')
  //         navigate('/requests')
  //     else
  //         setLoader(false)
  // }, [])

  useEffect(() => {
    // console.log("ID: ", id)
    if (id) {
      setIsShowButtons(false);
      setFrontImageData(null);
      setBackImageData(null);
      setCardsImageAuth(true);
      setChooseImage(false);
      dispatch(beforeCapture());
      setLoader(false);
    }
  }, [id]);
  useEffect(() => {
    if (errorSelector != null) {
      setLoader(false);
      dispatch(emptyError());
    }
  }, [errorSelector]);

  useEffect(() => {
    if (captureImageSelector.cardImageAuth) {
      // setFrontImageData(null);
      // setBackImageData(null);
      // setCardsImageAuth(true)
      // setChooseImage(false)
      navigate(`/capture-image/${captureImageSelector.cardImageData._id}`);
      // setLoader(false)
    }
  }, [captureImageSelector.cardImageAuth]);
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  // useEffect(() => {
  //     if (frontImageData != null && backImageData != null)
  //         stopCamera()
  // }, [frontImageData, backImageData])

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const startCamera = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        width: 1280,
        height: 720,
      },
    });
    setStream(stream);
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const captureImage = (cardSide) => {
    if (stream) {
      setLoader(true);
      const video = document.querySelector("video");
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0);
      const data = canvas.toDataURL("image/jpeg");
      if (cardSide == "passport") {
        setFrontImageData(data);
        toast.success("Passport Image Selected!", {
          toastId: "Passport-Image",
        });
        switchOffCamera();
      } else if (cardSide == "front") {
        setFrontImageData(data);
        toast.success("Front Image Captured!", { toastId: "Front-Image" });
      } else {
        setBackImageData(data);
        toast.success("Back Image Captured!", { toastId: "Back-Image" });
        switchOffCamera();
      }
      setLoader(false);
    }
  };

  const sendImageToServer = async (type) => {
    setLoader(true);
    const formData = new FormData();
    const frontfile = dataURLtoFile(frontImageData, `Card-Front.jpeg`);
    if (type === "id_card") {
      const backfile = dataURLtoFile(backImageData, `Card-Back.jpeg`);
      formData.append("cardFrontImage", frontfile);
      formData.append("cardBackImage", backfile);
    } else {
      formData.append("file", frontfile);
    }
    formData.append("region_name", "PAK");
    formData.append("document_type", selectedOption.value);
    // handle the response
    dispatch(uploadCard(formData));
  };

  const onFileChange = (e, cardSide) => {
    let file = e.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        if (cardSide == "passport") {
          toast.success("Passport Image Selected!", {
            toastId: "Passport-Image",
          });
          setFrontImageData(reader.result);
        } else if (cardSide == "front") {
          toast.success("Front Image Selected!", { toastId: "Front-Image" });
          setFrontImageData(reader.result);
        } else {
          toast.success("Back Image Selected!", { toastId: "Back-Image" });
          setBackImageData(reader.result);
        }
      };
    }
  };

  const switchOnCamera = () => {
    setIsCameraOn(true);
    startCamera();
  };

  const switchOffCamera = () => {
    stopCamera();
    setIsCameraOn(false);
  };

  const resetShowButtons = () => {
    if (isCameraOn) switchOffCamera();
    setChooseImage(false);
    setIsShowButtons(true);
    setFrontImageData(null);
    setBackImageData(null);
  };
  return loader ? (
    <FullPageLoader />
  ) : (
    <>
      <div className="dashboard-box capture-image h-100">
        <div className="mb-5 dashboard-block p-0 bg-none">
          <button
            className="back-button text-uppercase"
            onClick={() => navigate(`/requests`)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
        </div>
        <div className="dashboard-block ">
          {cardsImageAuth ? (
            <h1 className="mt-0 mb-4 mb-md-5">
              Verify Identity by uploading a video
            </h1>
          ) : (
            <>
              <h1 className="mt-0 mb-4 mb-md-5">
                Upload {selectedOption.label} Images
              </h1>
              <div className="cnic-images d-flex mb-5">
                <div className="instructions-block">
                  <h3>How does KYC work?</h3>
                  <ul className="instructions-to-upload">
                    <li>
                      To start with the verification process, select any of the two options: CNIC or Passport.
                    </li>
                    <li>
                    Now capture the front and back images of your selected document and submit.
                    </li>
                    <li>
                    You can take the images again if you find that the captured images are blurred and unreadable.
                    </li>
                    <li>
                    You can also upload the document files from your computer, smartphone, or any other compatible device.
                    </li>
                  </ul>
                </div>
                <div className="image-uploader-box d-flex align-items-center justify-content-center flex-column">
                  {isShowButtons ? (
                    <div className="d-flex flex-wrap flex-column">
                      <Select
                        defaultValue={selectedOption}
                        onChange={(e) => setSelectedOption(e)}
                        options={options}
                        className="mb-4 react-select"
                        classNamePrefix="react-select"
                      />
                      <button
                        className="orange-btn text-uppercase w-100 mb-4"
                        onClick={() => {
                          setIsShowButtons(false);
                          switchOnCamera();
                        }}
                      >
                        Capture images from camera
                      </button>
                      <button
                        className="orange-btn text-uppercase w-100"
                        onClick={() => {
                          setIsShowButtons(false);
                          setChooseImage(true);
                        }}
                      >
                        Choose from files
                      </button>
                    </div>
                  ) : (
                    <button
                      className="back-button text-uppercase mb-4"
                      onClick={resetShowButtons}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                  )}
                  {chooseImage && selectedOption.value === "id_card" ? (
                    <>
                      <div className="d-flex flex-wrap flex-column">
                        <Form className="profile-main-form mx-auto mb-4">
                          <Form.Group>
                            <div className="input-file-btn-holder ">
                              <Form.Control
                                className="text-white"
                                placeholder="Company"
                                type="file"
                                varient="info"
                                accept=".png,.jpeg,.jpg"
                                onChange={(e) => onFileChange(e, "front")}
                                id="imageUploader"
                              ></Form.Control>
                              <span className="orange-btn d-flex justify-content-center align-items-center text-uppercase">
                                Choose Front Image
                              </span>
                            </div>
                          </Form.Group>
                        </Form>
                        <Form className="profile-main-form mx-auto">
                          <Form.Group>
                            <div className="input-file-btn-holder ">
                              <Form.Control
                                className="text-white"
                                placeholder="Company"
                                type="file"
                                varient="info"
                                accept=".png,.jpeg,.jpg"
                                onChange={(e) => onFileChange(e, "back")}
                                id="imageUploader"
                              ></Form.Control>
                              <span className="orange-btn d-flex justify-content-center align-items-center text-uppercase">
                                Choose Back Image
                              </span>
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                    </>
                  ) : (
                    chooseImage && (
                      <>
                        <div className="d-flex flex-wrap flex-column">
                          <Form className="profile-main-form mx-auto mb-4">
                            <Form.Group>
                              <div className="input-file-btn-holder ">
                                <Form.Control
                                  className="text-white"
                                  placeholder="Company"
                                  type="file"
                                  varient="info"
                                  accept=".png,.jpeg,.jpg"
                                  onChange={(e) => onFileChange(e, "passport")}
                                  id="imageUploader"
                                ></Form.Control>
                                <span className="orange-btn d-flex justify-content-center align-items-center text-uppercase">
                                  Choose Image
                                </span>
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </>
          )}

          {isCameraOn && selectedOption.value === "id_card" ? (
            <>
              <div className="video-holder mb-3">
                <video ref={videoRef} autoPlay></video>
              </div>
              <button
                className="orange-btn text-uppercase mx-auto"
                onClick={() =>
                  captureImage(frontImageData == null ? "front" : "back")
                }
              >
                Capture {frontImageData == null ? "Front" : "Back"} Image
              </button>
            </>
          ) : (
            isCameraOn && (
              <>
                <div className="video-holder mb-3">
                  <video ref={videoRef} autoPlay></video>
                </div>
                <button
                  className="orange-btn text-uppercase mx-auto"
                  onClick={() => captureImage("passport")}
                >
                  Capture Passport Image
                </button>
              </>
            )
          )}
          {(frontImageData != null || backImageData != null) &&
          selectedOption.value === "id_card" ? (
            <>
              <div className="captured-images d-flex justify-content-center align-items-center pt-4">
                {frontImageData != null && (
                  <div className="captured-image">
                    <strong className="mt-0 mb-2 d-block">Front Image</strong>
                    <div className="image-holder">
                      <img className="img-fluid" src={frontImageData} alt="" />
                    </div>
                  </div>
                )}
                {backImageData != null && (
                  <div className="captured-image">
                    <strong className="mt-0 mb-2 d-block">Back Image</strong>
                    <div className="image-holder">
                      <img className="img-fluid" src={backImageData} alt="" />
                    </div>
                  </div>
                )}
              </div>
              {frontImageData != null && backImageData != null && (
                <div className="reset-actions d-flex justify-content-center">
                  <button
                    className="orange-btn text-uppercase mx-2 mb-2"
                    onClick={resetShowButtons}
                  >
                    Retake Images
                  </button>
                  <button
                    className="orange-btn text-uppercase mx-2 mb-2"
                    onClick={() => sendImageToServer("id_card")}
                  >
                    Submit
                  </button>
                </div>
              )}
            </>
          ) : (
            selectedOption.value === "passport" &&
            frontImageData != null && (
              <>
                <div className="captured-images d-flex justify-content-center align-items-center pt-4">
                  {frontImageData != null && (
                    <div className="captured-image">
                      <strong className="mt-0 mb-2 d-block">
                        Passport Image
                      </strong>
                      <div className="image-holder">
                        <img
                          className="img-fluid"
                          src={frontImageData}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
                {frontImageData != null && (
                  <div className="reset-actions d-flex justify-content-center">
                    <button
                      className="orange-btn text-uppercase mx-2 mb-2"
                      onClick={resetShowButtons}
                    >
                      Retake Image
                    </button>
                    <button
                      className="orange-btn text-uppercase mx-2 mb-2"
                      onClick={() => sendImageToServer("passport")}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </>
            )
          )}
          {cardsImageAuth && (
            <div className="d-flex">
              <button
                className="orange-btn text-uppercase mx-2 mb-2"
                onClick={() => navigate("/requests")}
              >
                Verify Later
              </button>
              <button
                className="orange-btn text-uppercase mx-2 mb-2"
                onClick={() => navigate(`/capture-video/${id}`)}
              >
                Verify Now
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CaptureImage;
