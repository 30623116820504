import React from 'react'
import { Container, Accordion } from "react-bootstrap";
import Lottie from 'react-lottie';
import StartedLottie from '../../../assets/lottie/RaqgQmGR88.json';
import { Link } from 'react-router-dom';

import './GetStarted.css';

const GetStarted = () => {
  return (
    <div className='get-started'>
        <Container>
            <div className='started-box'>
                <div className="started-data">
                    <h4>Get Started Today</h4>
                    <p>Know Your Customer is not only about individual screening and risk assessment. It’s about your reputation. Reduce false positives with KYC.</p>
                    <Link to="/requests" className='black-button' >Register Now</Link>
                </div>
                <div className="started-lottie">
                    <Lottie
                            options={{
                                animationData: StartedLottie,
                                loop: true,
                                autoplay: true,
                            }}
                            />
                </div>
            </div>
        </Container>
    </div>
  )
}

export default GetStarted