import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import terms from '../../../assets/images/terms.svg';
import disclaimer from '../../../assets/images/disclaimer.svg';
import limitations from '../../../assets/images/limitations.svg';
import license from '../../../assets/images/use-license.svg';




const Details = () => {
    return (
        <>
            <section className='details-sec  pb-0'>
                <Container className='customBox'>
                    <p className='custom-p'>You must be of legal age and have the authority to enter into this agreement to use the Application. By using the Application, you confirm that you meet these requirements. We grant you a non-exclusive, non-transferable, revocable license to use the Application for legitimate business and identity verification purposes.</p>
                    <Row className='termsRow'>
                        <Col xl={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src={terms} alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Acceptance of Terms</h2>
                                    <p>By accessing and using the KYC software application ("KYC" or "the Application"), you agree to be bound by the following Terms and Conditions.</p>
                                    <ul>
                                        <li>These terms govern your use of the software and its associated services. </li>
                                        <li>If you do not agree with any part of these terms, you should not use KYC.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src={license} alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Use License</h2>
                                    <p>Permission is granted to use the KYC software for the sole purpose of identity verification and compliance with AML regulations.</p>
                                    <ul>
                                        <li>You are responsible for the accuracy and legality of the information you input.</li>
                                        <li>This license is non-transferable and revocable.</li>
                                        <li>Unauthorized use, distribution, or modification of the software is strictly prohibited.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src={disclaimer} alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Disclaimer</h2>
                                    {/* <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper id. Aliquam erat volutpat. Integer sit amet laoreet eros.</p> */}
                                    <ul>
                                        <li>The KYC software is provided "as is" without any warranties, either express or implied.</li>
                                        <li>We do not guarantee the accuracy, reliability, or completeness of the information obtained through the software.</li>
                                        <li>Users are responsible for complying with applicable laws and regulations when using the software.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className='wrapper'>
                                <div className='img'>
                                    <img src={limitations} alt='' className='img-fluid' />
                                </div>
                                <div className='content'>
                                    <h2 className='H2'>Limitations</h2>
                                    {/* <p>Praesent efficitur lorem porttitor risus sollicitudin, id elementum sapien tincidunt. Maecenas convallis tempor metus, non porta libero semper id. Aliquam erat volutpat. Integer sit amet laoreet eros.</p> */}
                                    <ul>
                                        <li>In no event shall the KYC software be liable for any damages, including but not limited to financial losses, arising from the use or inability to use the software.</li>
                                        <li>We reserve the right to suspend or terminate access to the software at our discretion.</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='all-others'>
                        <h1>All <span className='theme'>others</span></h1>
                        <div className='all-others-content'>
                            <h4>Data Privacy</h4>
                            <p>We respect your data privacy. KYC collects and processes personal information in strict compliance with industry standards. By using KYC, you consent to our data practices.</p>
                            <h4>Security</h4>
                            <p>KYC employs robust security measures to protect data. You must not attempt to breach security, reverse engineer the software, or engage in any malicious activities.</p>
                            <h4>Compliance</h4>
                            <p>KYC helps you meet regulatory requirements. However, it is your responsibility to ensure compliance with relevant anti-money laundering laws and other regulations in your jurisdiction.</p>
                            <h4>Software Updates</h4>
                            <p>We may update KYC to improve functionality or comply with new regulations. Your continued use constitutes acceptance of these updates.</p>
                            <h4>Links</h4>
                            <p>The KYC software may contain links to external websites or resources. We are not responsible for the content or availability of these linked sites and do not endorse or warrant any products or services offered through them.</p>
                            <h4>Intellectual Property</h4>
                            <p>All rights to KYC, including intellectual property rights, belong to us. You may not copy, modify, or distribute KYC without our consent.</p>
                            <h4>Refund Policy</h4>
                            <p>Refunds are not provided for the KYC software, as it is a digital product. Please ensure that the software meets your requirements before making a purchase.</p>
                            <h4>Modifications</h4>
                            <p>We reserve the right to modify or update these terms and conditions at any time without prior notice. It is the user's responsibility to review the terms periodically.  </p>
                            <h4>Governing Law</h4>
                            <p>These terms and conditions are governed by the laws of [Your Jurisdiction], and any disputes arising from the use of the KYC software shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>
                            <h4>Contact Information</h4>
                            <p className='not-last'>If you have questions or concerns about KYC or these Terms, please contact us at [Your Contact Information].</p>
                            <p>By using KYC, you acknowledge that you have read, understood, and agree to these Terms and Conditions. These terms constitute a binding agreement between you and KYC's creators.</p>
                            {/* <div className='ul-list'>
                                <ul>
                                    <li>Aliquam erat volutpat. Integer sit amet laoreet eros</li>
                                    <li>Etiam feugiat sit amet urna eget efficitur</li>
                                    <li>Rutrum tincidunt dui hendrerit wam lacus eli faucibus id efficitur</li>
                                    <li>Hincidunt et est isque eget lacus non orci ultricies fringilla</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Details