import React, { useState, useEffect } from "react";
import "../Forms.css";
import { Container, Form, Tab, Tabs } from "react-bootstrap";
import formLogo from "../../../../assets/images/formLogo.png";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, beforeUser } from "../../Users/users.actions";
import ReCAPTCHA from "react-google-recaptcha";
import { ENV } from "../../../../config/config";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
import kycLogo from "../../../../assets/images/logo/kyc2.png";

const { recaptchaSiteKey } = ENV;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [captcha, setCaptcha] = useState("");
  const [errors, setErrors] = useState({});
  const [key, setKey] = useState("1");
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);

  const userSelector = useSelector((state) => state.user);
  const errorSelector = useSelector((state) => state.error);

  useEffect(() => {
    if (userSelector.userAuth) {
      if (userSelector.userData.success) {
        setLoader(false);
        dispatch(beforeUser());
        navigate("/requests");
      }
    }
  }, [userSelector.userAuth]);

  useEffect(() => {
    if (errorSelector) {
      setLoader(false);
    }
  }, [errorSelector]);

  const navigateToSignup = () => {
    // 👇️ navigate to /Login
    navigate("/signup");
  };

  const navigateToForgot = () => {
    // 👇️ navigate to /Forget
    navigate("/forgot");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      loginHandler();
    }
  };

  const loginHandler = () => {
    setSubmitted(true);

    let emailErr = "";
    let passErr = "";
    let captchaErr = "";

    if (!loginData.email) emailErr = "This field is required.";
    else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!loginData.email.match(regex)) emailErr = "Invalid Email";
    }

    if (!loginData.password) passErr = "This field is required.";

    if (!captcha) captchaErr = "This field is required.";

    setErrors({ email: emailErr, password: passErr, captcha: captchaErr });

    if (emailErr || passErr || captchaErr) return;

    setLoader(true);
    dispatch(login(loginData));
  };

  const onChange = (value) => {
    if (submitted && value) setErrors({ ...errors, captcha: "" });
    setCaptcha(value);
  };

  const onChangeHandler = (e) => {
    if (submitted) {
      if (e.target.value) {
        setErrors({ ...errors, [e.target.name]: "" });

        if (e.target.name === "email") {
          let regex =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!e.target.value.match(regex))
            setErrors({ ...errors, [e.target.name]: "Invalid Email" });
          else setErrors({ ...errors, [e.target.name]: "" });
        }
      } else {
        setErrors({ ...errors, [e.target.name]: "This field is required." });
      }
    }
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <section className="theme-form login">
        <div className="form-overlay"></div>
        <div className="biiview-form">
          <Container fluid className="formContainer">
            <div className="formLogo">
              <Link to="/">
                {" "}
                <img className="img-fluid" src={kycLogo} alt="KYC"></img>{" "}
              </Link>
            </div>
            <div className="wrapForm">
              <Form className="formStyle">
                <div className="formHeader">
                  {
                    <h2>
                      {key === "1" ? "Login your Account" : "Member Login"}
                    </h2>
                  }
                  <div className="d-flex align-items-end justify-content-center">
                    <p className="mb-0">
                      {key === "1" ? "NEW USER?" : "BECOME MEMBER?"}
                    </p>
                    <button
                      onClick={navigateToSignup}
                      className="link orangeLinkBtn ms-2"
                    >
                      REGISTER
                    </button>
                  </div>
                </div>
                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Email Address</Form.Label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Email Address"
                    name="email"
                    value={loginData.email}
                    onChange={onChangeHandler}
                  ></input>
                  {errors && errors.email && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.email}
                    </span>
                  )}
                </Form.Group>
                <Form.Group
                  className="formGroup"
                  controlId="formBasicEmail"
                  onKeyPress={handleKeyPress}
                >
                  <Form.Label>Password</Form.Label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="password"
                    placeholder="Enter Your Password"
                    value={loginData.password}
                    onChange={onChangeHandler}
                  ></input>
                  {errors && errors.password && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.password}
                    </span>
                  )}
                </Form.Group>
                <div className="theme-capcha">
                  <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChange} />
                  {errors && errors.captcha && (
                    <span style={{ fontSize: "12px" }} className=" text-danger">
                      {errors.captcha}
                    </span>
                  )}
                  <button
                    type="button"
                    className="orange-btn text-uppercase end-mb"
                    onClick={loginHandler}
                  >
                    log in
                  </button>
                </div>
                <div className="page-link d-flex align-items-end justify-content-center text-uppercase">
                  <p className="mb-0 link outer-p" onClick={navigateToForgot}>
                    FORGOT YOUR PASSWORD?
                  </p>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};
export default Login;
