import React, { useEffect, useState } from 'react';
import './Requests.css';
// import SiteCards from '../Home/Explore/SiteCards';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ENV } from '../../../config/config';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { beforeRequests, getRequest } from './requests.actions';

const Request = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [loader, setLoader] = useState(true)
    const [myRequest, setMyRequest] = useState()
    const [imageUrl, setImageUrl] = useState(null)
    const [show, setShow] = useState(false)
    const requestSelector = useSelector((state) => state.request)

    useEffect(() => {
        dispatch(getRequest(id))
    }, [id])

    useEffect(() => {
        if (requestSelector.requestAuth) {
            setMyRequest(requestSelector.requestData.request)
            dispatch(beforeRequests())
            setLoader(false)
        }
    }, [requestSelector.requestAuth])


    // useEffect(() => {
    //     console.log("myRequests: ", myRequests)
    // }, [myRequests])

    const handleClose = () => {
        setImageUrl(null);
        setShow(false)
    }

    const handleOpen = (url) => {
        setImageUrl(url);
        setShow(true)
    }

    return (
        loader ?
            (
                <FullPageLoader />
            )
            :
            (
                <div className='request-page'>
                    <div className='container'>
                        {
                            myRequest && myRequest.documentType === 'id_card' ? (
                                <div className="">
                                <div className="col-12">
                                    <div className="mb-5">
                                        <button className="back-button text-uppercase" onClick={() => navigate(`/requests`)}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </button>
                                    </div>
                                </div>
                                <div className="request-item mb-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h4 className="mt-0 mb-3 mb-sm-5">Front Image</h4>
                                                    <div className="uploaded-image-holder cursor-pointer">
                                                        <img src={ENV.assetUrl + myRequest.result.cardFrontImage} onClick={() => handleOpen(ENV.assetUrl + myRequest.result.cardFrontImage)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h4 className="mt-0 mb-3 mb-sm-5">Back Image</h4>
                                                    <div className="uploaded-image-holder cursor-pointer">
                                                        <img src={ENV.assetUrl + myRequest.result.cardBackImage} onClick={() => handleOpen(ENV.assetUrl + myRequest.result.cardBackImage)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Name:</strong>
                                                        <span>{myRequest.result.name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Guardian Name:</strong>
                                                        <span>{myRequest.result.guardian_name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Gender:</strong>
                                                        <span>{myRequest.result.gender}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Status:</strong>
                                                        <span className={myRequest.status === "approved" ? "text-success" : myRequest.status === "pending" ? "text-warning":"text-danger"}>{myRequest.status}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Country:</strong>
                                                        <span>{myRequest.result.country}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Card Number:</strong>
                                                        <span>{myRequest.result.id_number}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Issue Date:</strong>
                                                        <span>{moment(myRequest.result.issue_date, 'YYMMDD').format('DD MMM, YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Expiry Date:</strong>
                                                        <span>{moment(myRequest.result.expiry_date, 'YYMMDD').format('DD MMM, YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Birth Date:</strong>
                                                        <span>{moment(myRequest.result.birth_date, 'YYMMDD').format('DD MMM, YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            myRequest.status == "pending" ?
                                                (
                                                    <div className="col-12">
                                                        <div className="pt-2">
                                                            <button className="orange-btn text-uppercase" onClick={() => navigate(`/capture-video/${myRequest._id}`)}>Verify</button>
                                                        </div>
                                                    </div>
                                                )
                                                : myRequest.status == "rejected" ? (
                                                    <div className="col-12">
                                                        <div className="pt-2">
                                                            <button className="orange-btn text-uppercase" onClick={() => navigate(`/capture-video/${myRequest._id}`)}>Re-Verify</button>
                                                        </div>
                                                    </div>
                                                )
                                                    : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            (
                                <div className="">
                                <div className="col-12">
                                    <div className="mb-5">
                                        <button className="back-button text-uppercase" onClick={() => navigate(`/requests`)}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </button>
                                    </div>
                                </div>
                                <div className="request-item mb-4">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h4 className="mt-0 mb-3 mb-sm-5">Image</h4>
                                                    <div className="uploaded-image-holder cursor-pointer">
                                                        <img src={ENV.assetUrl + myRequest.result.file} onClick={() => handleOpen(ENV.assetUrl + myRequest.result.file)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Name:</strong>
                                                        <span>{myRequest.result.name}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Nationality:</strong>
                                                        <span>{myRequest.result.nationality}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Gender:</strong>
                                                        <span>{myRequest.result.gender}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Status:</strong>
                                                        <span className={myRequest.status === "approved" ? "text-success" : myRequest.status === "pending" ? "text-warning":"text-danger"}>{myRequest.status}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Passport Number:</strong>
                                                        <span>{myRequest.result.passport_number}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Card Number:</strong>
                                                        <span>{myRequest.result.id_number}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Expiry Date:</strong>
                                                        <span>{moment(myRequest.result.expiry_date, 'YYMMDD').format('DD MMM, YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="d-flex mb-3 px-2">
                                                        <strong className="me-2">Birth Date:</strong>
                                                        <span>{moment(myRequest.result.date_of_birth, 'YYMMDD').format('DD MMM, YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            myRequest.status == "pending" ?
                                                (
                                                    <div className="col-12">
                                                        <div className="pt-2">
                                                            <button className="orange-btn text-uppercase" onClick={() => navigate(`/capture-video/${myRequest._id}`)}>Verify</button>
                                                        </div>
                                                    </div>
                                                )
                                                : myRequest.status == "rejected" ? (
                                                    <div className="col-12">
                                                        <div className="pt-2">
                                                            <button className="orange-btn text-uppercase" onClick={() => navigate(`/capture-video/${myRequest._id}`)}>Re-Verify</button>
                                                        </div>
                                                    </div>
                                                )
                                                    : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            )
                            
                        }
                    </div>
                    <Modal size="lg" show={show} onHide={handleClose} className='theme-modal request-detail-image-modal'>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="modal-captured-image-holder">
                                            <img src={imageUrl} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                </div>
            )


    )
}
export default Request