import React from "react";
import logo from "../../../assets/images/logo/kyc2.png";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Header.css";

const Header = () => {
  return (
    <div className="main-header">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img className="img-fluid" src={logo} alt="Logo" />
          </Navbar.Brand>
          <div className="flex-div d-flex align-items-center justify-content-end">
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav">
              
            </Navbar.Toggle> */}
            {/* <Navbar.Collapse className=""> */}
              {/* <NavDropdown title="Solutions" id="basic-nav-dropdown">
                <NavDropdown.Item href="">Action</NavDropdown.Item>
                <NavDropdown.Item href="">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                  Something
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Resources" id="basic-nav-dropdown">
                <NavDropdown.Item href="">Action</NavDropdown.Item>
                <NavDropdown.Item href="">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                  Something
                </NavDropdown.Item>
              </NavDropdown> */}
              {/* <Nav.Link href="">Solutions</Nav.Link>
              <Nav.Link href="">Resources</Nav.Link>
              <Nav.Link href="">Contact Us</Nav.Link>
            </Navbar.Collapse> */}
            <div classNAme="action-button">
              <Link to="/requests" className="main-button">REGISTER</Link>
            </div>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
