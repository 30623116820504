import React from 'react'
import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

import './Footer.css';

const Footer = () => {
  return (
    <div className='main-footer'>
        <Container>
          <div className='text-center'>
            <h5>Copyright © 2023 <Link to="/">kyc.arhamsoft.org</Link></h5>
          </div>
        </Container>
    </div>
  )
}

export default Footer