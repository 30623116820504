import { CARD_IMAGE, FACE_VIDEO, BEFORE_CAPTURE_IMAGE } from '../../../redux/types';


const initialState = {
    cardImageData: null,
    cardImageAuth: false,

    videoData: null,
    videoAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CARD_IMAGE:
            return {
                ...state,
                cardImageData: action.payload,
                cardImageAuth: true
            }
        case FACE_VIDEO:
            return {
                ...state,
                videoData: action.payload,
                videoAuth: true
            }
        case BEFORE_CAPTURE_IMAGE:
            return {
                ...state,
                cardImageData: null,
                cardImageAuth: false,

                videoData: null,
                videoAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}