//layouts
import Auth from './components/Frontend/Layouts/Auth';
import Dashboard from './components/Frontend/Layouts/Dashboard';
import MemberDashboard from './components/Frontend/Layouts/MemberDashboard';
import MainLayout from './components/HomePage/Layout/MainLayout';

import { Navigate } from 'react-router-dom';

//components
import Signup from './components/Frontend/Forms/Signup/Signup';
import Login from './components/Frontend/Forms/Login/Login';
import Member from './components/Frontend/Forms/Member/Member';
import ForgotPass from './components/Frontend/Forms/ForgotPass/ForgotPass';
import ResetPassword from './components/Frontend/Forms/ResetPassword/resetPassword';
import MemberLogin from './components/Frontend/Forms/MemberLogin/MemberLogin';
import DashboardHome from './components/Frontend/DashboardHome/DashboardHome';
import Profile from './components/Frontend/Profile/profile';
import NotFound from './components/Frontend/NotFound/NotFound';
import Requests from './components/Frontend/Requests/Requests';
import CaptureImage from './components/Frontend/CaptureImage/CaptureImage';
import VideoRecorder from './components/Frontend/CaptureImage/VideoRecorder';
import Request from './components/Frontend/Requests/Request';
import Template from './components/HomePage/Template/Template';
import TermsConditions from './components/HomePage/TermsConditions/TermsConditions';


const routes = [
  { path: "/", exact: true, name: "Home", layout: MainLayout, component: Template },
  { path: "/terms-conditions", exact: true, name: "Terms Conditions", layout: MainLayout, component: TermsConditions },
  { path: "/signup", exact: true, name: "Sign Up", layout: Auth, component: Signup },
  { path: "/login", exact: true, name: "Login", layout: Auth, component: Login },
  { path: "/forgot", exact: true, name: "Forget Password", layout: Auth, component: ForgotPass },
  { path: "/reset-password/:token", exact: true, name: "Reset Password", layout: Auth, component: ResetPassword },
  { path: "/capture-image", exact: true, name: "Dashboard", layout: Dashboard, component: CaptureImage },
  { path: "/capture-image/:id", exact: true, name: "Dashboard", layout: Dashboard, component: CaptureImage },
  { path: "/capture-video/:id", exact: true, name: "Dashboard", layout: Dashboard, component: VideoRecorder },
  { path: "/dashboard", exact: true, name: "Dashboard", layout: Dashboard, component: DashboardHome },
  { path: "/request/:id", exact: true, name: "My Requests", layout: Dashboard, component: Request },
  { path: "/requests", exact: true, name: "My Requests", layout: Dashboard, component: Requests },
  { path: "/dashboard/:url", exact: true, name: "Dashboard", layout: Dashboard, component: DashboardHome },
  { path: "/profile", exact: true, name: "Profile", layout: MemberDashboard, component: Profile },
  { path: "/*", exact: true, name: "Not Found", layout: Auth, component: NotFound },
  

]

export default routes;