// import React from 'react'
import React, { useState, useEffect } from 'react'
import '../Forms.css'
import { Container, Form } from 'react-bootstrap'
import formLogo from '../../../../assets/images/formLogo.png'
import { useNavigate, Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { FaCalendarDay } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux'
import { signup, beforeUser } from '../../Users/users.actions';
import { ENV } from '../../../../config/config';

const { recaptchaSiteKey } = ENV

const Member = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [signupData, setSignupData] = useState({
        fullName: '',
        email: '',
        password: '',
        dob: ''
    })
    const [captcha, setCaptcha] = useState('')
    const [errors, setErrors] = useState({})

    const userSelector = useSelector((state) => state.user)
    const errorSelector = useSelector((state) => state.error)

    useEffect(() => {
        if (userSelector.userSignupAuth) {
            if (userSelector.userSignupData.success) {
                dispatch(beforeUser())
                // navigate('/member-login')
            }
        }
    }, [userSelector.userSignupAuth])

    // useEffect(() => {
    //     if (errorSelector) {
    //         console.log(errorSelector)
    //     }
    // }, [errorSelector])

    const navigateToLogin = () => {
        navigate('/login');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            signupHandler();
        }
    }

    const signupHandler = () => {

        let fullNameErr = ''
        let emailErr = ''
        let dobErr = ''
        let passErr = ''
        let captchaErr = ''
        let verifyPassErr = ''
        let msg = 'This field is required.'

        if (!signupData.fullName)
            fullNameErr = msg
        else {
            let regex = /^[a-zA-Z ]{3,30}$/;
            if (!signupData.fullName.match(regex))
                fullNameErr = 'Name should be atleast 3 characters long and should only contain alphabets'
        }

        if (!signupData.email)
            emailErr = msg

        else {
            let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!signupData.email.match(regex))
                emailErr = 'Invalid Email'
        }

        if (!signupData.password)
            passErr = msg

        if (!signupData.verifyPassword)
            verifyPassErr = msg

        if (!signupData.dob)
            dobErr = msg


        if (!captcha)
            captchaErr = msg


        setErrors({
            fullName: fullNameErr,
            email: emailErr,
            password: passErr,
            verifyPassword: verifyPassErr,
            dob: dobErr,
            captcha: captchaErr,
        })

        if (fullNameErr || emailErr || passErr || verifyPassErr || dobErr || captchaErr)
            return

        delete signupData['verifyPassword']
        document.getElementById('verifyPasswordInput').value = ''
        dispatch(signup(signupData))

    };


    return (
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                <div className='biiview-form'>
                    <Container fluid className='formContainer'>
                        <div className='formLogo'>
                            <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                        </div>

                        <div className='wrapForm'>
                            <Form className='formStyle'>
                                <div className='formHeader'>
                                    <h2>Become Member</h2>
                                    <div className='d-flex align-items-end justify-content-center'>
                                        <p className='mb-0'>ALREADY MEMBER?</p>
                                        <Link to='/memberLogin' onClick={navigateToLogin} className='link ms-2 themeLink'>LOGIN</Link></div>
                                </div>

                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>Full Name</Form.Label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Your Name"
                                        value={signupData.fullName}
                                        onChange={(e) => setSignupData({ ...signupData, fullName: e.target.value })}
                                        >
                                    </input>
                                    {
                                        errors && errors.fullName &&
                                        <span style={{ fontSize: '12px' }} className='text-danger'>{errors.fullName}</span>
                                    }
                                </Form.Group>

                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Your Email Address"
                                        value={signupData.email}
                                        onChange={(e) => setSignupData({ ...signupData, email: e.target.value })}>
                                    </input>
                                    {
                                        errors && errors.email &&
                                        <span style={{ fontSize: '12px' }} className='text-danger'>{errors.email}</span>
                                    }
                                </Form.Group>

                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>Password</Form.Label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter Your Password"
                                        value={signupData.password}
                                        onChange={(e) => setSignupData({ ...signupData, password: e.target.value })}
                                        >
                                    </input>
                                    {
                                        errors && errors.password &&
                                        <span style={{ fontSize: '12px' }} className='text-danger'>{errors.password}</span>
                                    }
                                </Form.Group>

                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>Verify Password</Form.Label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="verifyPasswordInput"
                                        placeholder="Re-Enter Password"
                                        value={signupData.verifyPassword}
                                        onChange={(e) => setSignupData({ ...signupData, verifyPassword: e.target.value })}
                                        >
                                    </input>
                                    {
                                        errors && errors.verifyPassword &&
                                        <span style={{ fontSize: '12px' }} className='text-danger'>{errors.verifyPassword}</span>
                                    }
                                </Form.Group>

                                <Form.Group className='formGroup' controlId="formBasicEmail">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <div className='inputDiv calendar-block'>
                                    <FaCalendarDay />
                                       
                                        <input
                                            type="date"
                                            className="form-control theme-calendar"
                                            placeholder="Select DOB"
                                            value={signupData.dob}
                                            onChange={(e) => setSignupData({ ...signupData, dob: e.target.value })}
                                            >
                                        </input>
                                        {/* <FaCalendarDay /> */}
                                    </div>
                                    {
                                        errors && errors.dob &&
                                        <span style={{ fontSize: '12px' }} className='text-danger'>{errors.dob}</span>
                                    }
                                    {/* <SimpleReactCalendar activeMonth={new Date()} /> */}
                                </Form.Group>

                                <div className='theme-capcha'>
                                    <div className='captchaStyle'>
                                        <ReCAPTCHA
                                            sitekey={recaptchaSiteKey}
                                            onChange={(value) => setCaptcha(value)}
                                        />
                                    </div>
                                    {
                                        errors && errors.captcha &&
                                        <span style={{ fontSize: '12px' }} className='form-control text-danger'>{errors.captcha}</span>
                                    }
                                    <button type="button" className='orange-btn text-uppercase' onClick={signupHandler}>CONTINUE</button>
                                    
                                </div>
                            </Form>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}
export default Member