// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// USERS
export const BEFORE_USER = 'BEFORE_USER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_SIGNUP = 'USER_SIGNUP';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_USER = 'GET_USER';
export const GET_PLAN_DETAILS = 'GET_PLAN_DETAILS';
export const BEFORE_USER_PLAN = 'BEFORE_USER_PLAN'

// EMAILS
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const BEFORE_EMAIL = 'BEFORE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const ADD_EMAIL = 'ADD_EMAIL';
export const DELETE_EMAIL = 'DELETE'

// SITE SETTINGS
export const GET_SITE_SETTINGS = 'GET_SITE_SETTINGS';
export const BEFORE_SITE_SETTINGS = 'BEFORE_SITE_SETTINGS';

//DASHBOARD
export const GET_TOP_VIDEOS = 'GET_TOP_VIDEOS'
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD'

// CaptureImage
export const CARD_IMAGE = 'CARD_IMAGE'
export const FACE_VIDEO = 'FACE_VIDEO'
export const BEFORE_CAPTURE_IMAGE = 'BEFORE_CAPTURE_IMAGE'

// KYC
export const REQUEST = 'REQUEST'
export const REQUESTS = 'REQUESTS'
export const DELETE_REQUEST = 'DELETE_REQUEST'
export const BEFORE_REQUESTS = 'BEFORE_REQUESTS'
