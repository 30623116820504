import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
// import MainSidebar from '../MainSidebar/MainSidebar'

const Dashboard = ({ title, children }) => {

    const [sidebar, setSidebar] = useState(true)
    useEffect(() => {
        if (title)
            document.title = title;
        else
            document.title = "BIIVIEW";
    }, [title]);

    return (
        <div className="site-pages-outer">
            <Header />
            {/* <MainSidebar sidebar={sidebar} setSidebar={setSidebar} /> */}
            <div className={sidebar ? 'sitePages  sitePages-main-width' : 'sitePages sitePages-width'}>
                {children}
            </div>
        </div>
    )
}

export default Dashboard